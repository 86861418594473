import React from 'react'

const AboutUs = () => {
	return (
		<>
			<div className="container my-4">
				<div className="row">
					<div className="card shadow-sm border-0">
						<div className="card-body">
							<h1 className='fw-700 fs-22 mb-3'>About Us</h1>
							<p className='mb-5'>OAMBOO services are designed to position ourselves as the top designing company in the competitive global market. Our web services include web development and web designing is capsuled to provide a complete designing, ERP, CRM, and Management application solution.</p>
							<h1 className='fw-700 fs-22 mb-3'>Our Vision</h1>
							<p className='mb-5'>Our vision is to become the most trusted company in our field - to create a name for ourselves through complete client satisfaction. We also hope to create the best team which would handle any situation with focus, dedication and professionalism and would strive to serve our customers to their full potential.</p>
							<h1 className='fw-700 fs-22 mb-3'>Our Mission</h1>
							<p className='mb-5'>Our mission is to fully satisfy our clients through effective services. We want to meet every challenge that comes our way with thorough professionalism. We feel it is our duty to keep our customers' requirements in mind and make it easier for him/her to conduct their business with the help of innovation and solution.</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AboutUs
