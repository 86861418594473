export const tokenManager = {
    getToken: function () {
        const token = sessionStorage.getItem('token');
        return token || null;
    },

    setToken: function (token: string) {
        sessionStorage.setItem('token', token);
    },

    removeToken: function () {
        if (this.getToken()) {
            sessionStorage.removeItem('token');
        }
    },
};
