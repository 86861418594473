import React from 'react'

function FitnessEquipments() {
	return (
		<>
			<section className='product-banner'>
				<div className="container">
					<div className="hero-title text-center">
						<h1 className="fs-28 fw-700"><span>Fitness</span> Shopping Zone</h1>
						<p className="fs-16">Unlock Your Best Self, Discover the Perfect Nutrition Consultant for Your Wellness Journey!</p>
						<div className="input-group input-group-lg page-data-filter">
							<span className="input-group-text pe-0 ps-2 bg-white"><i className="bi-list fs-14"></i></span>
							<button className="btn dropdown-toggle border-end-0 fs-14 home-filter-drop d-flex align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Select Equipment</button>
							<ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0 dropstart">
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Nutrition" className="w-100 mb-0 fw-400">Hyderabad</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Suppliement" className="w-100 mb-0 fw-400">Secendrabad</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Fitnewss" className="w-100 mb-0 fw-400">Telangana</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="ServiceVender" className="w-100 mb-0 fw-400">Andhra Pradesh</label>
								</li>
							</ul>
							<button className="btn dropdown-toggle border-end-0 fs-14 home-filter-drop d-flex align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Select Brand</button>
							<ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0 dropstart">
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Nutrition" className="w-100 mb-0 fw-400">Hyderabad</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Suppliement" className="w-100 mb-0 fw-400">Secendrabad</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Fitnewss" className="w-100 mb-0 fw-400">Telangana</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="ServiceVender" className="w-100 mb-0 fw-400">Andhra Pradesh</label>
								</li>
							</ul>
							<input type="text" className="form-control fs-14 border-start border-secondary" placeholder="Search with service center name or vendor name..." />
							<button type="button" className="btn btn-primary search-btn"><i className="bi-search"></i></button>
						</div>
						<div className="m-auto col-9 mt-5">
							<p className='fs-16'>Join our thriving community of wellness professionals! Register your clinic or nutrition consultancy on our website and connect with clients seeking your expertise. Elevate your practice and expand your reach with us!</p>
							<button type='button' className='btn btn-primary rounded-pill'>Register Now</button>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5">
				<div className="container">
					<div className="d-flex align-items-center justify-content-between text-center slider-sec">
						<i className="bi-arrow-left-circle fs-28"></i>
						<div className="col-lg col-6">
							<div className="home-service-card">
								<img src={require('./../assets/images/treadmill.png')} alt="" />
								<h2 className="fs-14 fw-600">Tread Mill</h2>
							</div>
						</div>
						<div className="col-lg col-6">
							<div className="home-service-card">
								<img src={require('./../assets/images/cycle.png')} alt="" />
								<h2 className="fs-14 fw-600">Cyciling</h2>
							</div>
						</div>
						<div className="col-lg col-6">
							<div className="home-service-card">
								<img src={require('./../assets/images/cross-trainer.png')} alt="" />
								<h2 className="fs-14 fw-600">Cross Trainer</h2>
							</div>
						</div>
						<div className="col-lg col-6">
							<div className="home-service-card">
								<img src={require('./../assets/images/cable-crossover.png')} alt="" />
								<h2 className="fs-14 fw-600">Others GYM Equipments</h2>
							</div>
						</div>
						<i className="bi-arrow-right-circle fs-28"></i>
					</div>
				</div>
			</section>
		</>
	)
}

export default FitnessEquipments