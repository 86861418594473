
import { ApiResponseType } from '../../types/api';
import { axiosInstance } from './index';

export const branchService = {
    async getBranchesByCustId(custId: number) {
        const { data: responseData } = await axiosInstance.get(`gymbranchs/getBranchesByCustId/${custId}`);
        return responseData as ApiResponseType;
    },
    async createGymBranch(data: any) {
        const { data: responseData } = await axiosInstance.post(`gymbranchs/createGymBranch`, data);
        return responseData as ApiResponseType;
    },
    async getGymBranchesByFilter(branchStatus: any, branchName: any,branchContactNo: any) {
        const { data: responseData } = await axiosInstance.get(`gymbranchs/getGymBranchesByFilter/${branchStatus}/${branchName}/${branchContactNo}`);
        return responseData as ApiResponseType;
    },
    async getGymBranchById(branchId: number) {
        const { data: responseData } = await axiosInstance.get(`gymbranchs/getGymBranchById/${branchId}`);
        return responseData as ApiResponseType;
    },
    async updateGymBranch(branchId: number, data: any) {
        const { data: responseData } = await axiosInstance.put(`gymbranchs/updateGymBranch/${branchId}`, data);
        return responseData as ApiResponseType;
    },
};