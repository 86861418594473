import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Customer } from '../../types/customer';

type InitialStateType = {
    loading: boolean;
    profile: Customer | null;
};

const initialState: InitialStateType = {
    loading: true,
    profile: null,
};

const customerInfoSlice = createSlice({
    name: 'customerInfo',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setCustomerInfo(state, action: PayloadAction<Customer>) {
            state.profile = action.payload;
        },
        deleteCustomerInfo(state) {
            state = initialState;
        },
    },
});

export function getCustomer(doctor: any) {
    return async (dispatch: Dispatch) => {
        try {
            console.log('redux getCustomer');
            dispatch(customerInfoSlice.actions.setLoading(true));
            if (doctor) {
                dispatch(customerInfoSlice.actions.setCustomerInfo(doctor));
            }
        } catch (err) {
            console.log('error getCustomer', err);
        } finally {
            dispatch(customerInfoSlice.actions.setLoading(false));
        }
    };
};

export function signOut() {
    return async (dispatch: Dispatch) => {
        dispatch(customerInfoSlice.actions.deleteCustomerInfo());
    };
};

export function useDoctorInfo() {
    return useSelector((state: any) => state.doctor)
}

export default customerInfoSlice.reducer;
export const { setLoading, setCustomerInfo, deleteCustomerInfo } = customerInfoSlice.actions;