import React from 'react'
import { Link } from 'react-router-dom'

const NutritionSearchResults = () => {
    return (
        <>
            <div className="container my-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <span className='fw-700 fs-14'>256 Results Found</span>
                    </div>
                    <div>
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Sort By</button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card shadow-sm border-0 rounded-4 mb-4">
                    <div className="card-body d-block d-lg-flex align-items-center justify-content-between">
                        <div className='d-block d-lg-flex align-items-center justify-content-between w-100 border-end pe-3'>
                            <Link to="/consultant-page" className="d-flex align-items-center w-100">
                                <img src={require('../assets/images/avthar-lg.png')} alt="" />
                                <div className='ms-lg-3 ms-3'>
                                    <h3 className='fw-600 fs-18 primary-text'>Dr. Shaniqua Propst</h3>
                                    <p className='fs-14 mb-2'>Clinical Nutritionist</p>
                                    <p className='fs-14 mb-0'>MBBS | FCPS</p>
                                </div>
                            </Link>
                            <div className='text-center'>
                                <p className='mb-0'>Fee Starting</p>
                                <p className='mb-2'>from <i className='bi-currency-rupee'></i>350</p>
                                <button className='btn btn-primary rounded-pill btn-sm text-nowrap'>Book Appointment</button>
                            </div>
                        </div>
                        <div className='ps-3 mt-3 mt-lg-0'>
                            <p className='mb-2 text-nowrap'><i className='bi-clock'></i> Available Today</p>
                            <p className='mb-2 text-nowrap'><i className='bi-hand-thumbs-up'></i> 99% (11009 likes)</p>
                            <p className='mb-2 text-nowrap'><i className='bi-chat-left-text'></i> 815 Reviews</p>
                            <div className='me-2'>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-half fs-18 text-warning me-1"></i>
                                <i className="bi-star-half fs-18 text-warning me-1"></i>
                                <span>4.3</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow-sm border-0 rounded-4 mb-4">
                    <div className="card-body d-block d-lg-flex align-items-center justify-content-between">
                        <div className='d-block d-lg-flex align-items-center justify-content-between w-100 border-end pe-3'>
                            <Link to="/consultant-page" className="d-flex align-items-center w-100">
                                <img src={require('../assets/images/avthar-lg.png')} alt="" />
                                <div className='ms-lg-3 ms-3'>
                                    <h3 className='fw-600 fs-18 primary-text'>Dr. Shaniqua Propst</h3>
                                    <p className='fs-14 mb-2'>Clinical Nutritionist</p>
                                    <p className='fs-14 mb-0'>MBBS | FCPS</p>
                                </div>
                            </Link>
                            <div className='text-center'>
                                <p className='mb-0'>Fee Starting</p>
                                <p className='mb-2'>from <i className='bi-currency-rupee'></i>350</p>
                                <button className='btn btn-primary rounded-pill btn-sm text-nowrap'>Book Appointment</button>
                            </div>
                        </div>
                        <div className='ps-3 mt-3 mt-lg-0'>
                            <p className='mb-2 text-nowrap'><i className='bi-clock'></i> Available Today</p>
                            <p className='mb-2 text-nowrap'><i className='bi-hand-thumbs-up'></i> 99% (11009 likes)</p>
                            <p className='mb-2 text-nowrap'><i className='bi-chat-left-text'></i> 815 Reviews</p>
                            <div className='me-2'>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-half fs-18 text-warning me-1"></i>
                                <i className="bi-star-half fs-18 text-warning me-1"></i>
                                <span>4.3</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow-sm border-0 rounded-4 mb-4">
                    <div className="card-body d-block d-lg-flex align-items-center justify-content-between">
                        <div className='d-block d-lg-flex align-items-center justify-content-between w-100 border-end pe-3'>
                            <Link to="/consultant-page" className="d-flex align-items-center w-100">
                                <img src={require('../assets/images/avthar-lg.png')} alt="" />
                                <div className='ms-lg-3 ms-3'>
                                    <h3 className='fw-600 fs-18 primary-text'>Dr. Shaniqua Propst</h3>
                                    <p className='fs-14 mb-2'>Clinical Nutritionist</p>
                                    <p className='fs-14 mb-0'>MBBS | FCPS</p>
                                </div>
                            </Link>
                            <div className='text-center'>
                                <p className='mb-0'>Fee Starting</p>
                                <p className='mb-2'>from <i className='bi-currency-rupee'></i>350</p>
                                <button className='btn btn-primary rounded-pill btn-sm text-nowrap'>Book Appointment</button>
                            </div>
                        </div>
                        <div className='ps-3 mt-3 mt-lg-0'>
                            <p className='mb-2 text-nowrap'><i className='bi-clock'></i> Available Today</p>
                            <p className='mb-2 text-nowrap'><i className='bi-hand-thumbs-up'></i> 99% (11009 likes)</p>
                            <p className='mb-2 text-nowrap'><i className='bi-chat-left-text'></i> 815 Reviews</p>
                            <div className='me-2'>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-fill fs-18 text-warning me-1"></i>
                                <i className="bi-star-half fs-18 text-warning me-1"></i>
                                <i className="bi-star-half fs-18 text-warning me-1"></i>
                                <span>4.3</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NutritionSearchResults
