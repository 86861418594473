import React from 'react'
import { Link } from 'react-router-dom'

const ServiceVendors = () => {
	return (
		<>
			<section className="d-flex align-items-center justify-content-center hero-banner-section">
				<div className="hero-title text-center col-12 col-lg-7">
					<h1 className="fs-28 fw-700"><span>GYM Entrance</span> Service Vendors Zone</h1>
					<p className="fs-16">Our all-in-one system is designed to simplify gym management for you.</p>
					<div className="input-group input-group-lg page-data-filter">
						<span className="input-group-text pe-0 ps-2 bg-white"><i className="bi-geo-alt fs-14"></i></span>
						<button className="btn dropdown-toggle border-end-0 fs-14 home-filter-drop d-flex align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Select Location</button>
						<ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0 dropstart">
							<li className="d-flex align-items-center px-2 py-2">
								<label htmlFor="Nutrition" className="w-100 mb-0 fw-400">Hyderabad</label>
							</li>
							<li className="d-flex align-items-center px-2 py-2">
								<label htmlFor="Suppliement" className="w-100 mb-0 fw-400">Secendrabad</label>
							</li>
							<li className="d-flex align-items-center px-2 py-2">
								<label htmlFor="Fitnewss" className="w-100 mb-0 fw-400">Telangana</label>
							</li>
							<li className="d-flex align-items-center px-2 py-2">
								<label htmlFor="ServiceVender" className="w-100 mb-0 fw-400">Andhra Pradesh</label>
							</li>
						</ul>
						<input type="text" className="form-control fs-14 border-start border-secondary" placeholder="Search with service center name or vendor name..." />
						<button type="button" className="btn btn-primary search-btn"><i className="bi-search"></i></button>
					</div>
					<div className="container-fluid mt-5">
						<div className="d-flex align-items-center justify-content-between text-center slider-sec">
							<div className="col-lg col-6">
								<div className="home-service-card">
									<img src={require('./../assets/images/treadmill.png')} alt="" />
									<h2 className="fs-14 fw-600">Tread Mill Service</h2>
								</div>
							</div>
							<div className="col-lg col-6">
								<div className="home-service-card">
									<img src={require('./../assets/images/cycle.png')} alt="" />
									<h2 className="fs-14 fw-600">Cyciling Service</h2>
								</div>
							</div>
							<div className="col-lg col-6">
								<div className="home-service-card">
									<img src={require('./../assets/images/cross-trainer.png')} alt="" />
									<h2 className="fs-14 fw-600">Cross Trainer Service</h2>
								</div>
							</div>
							<div className="col-lg col-6">
								<div className="home-service-card">
									<img src={require('./../assets/images/cable-crossover.png')} alt="" />
									<h2 className="fs-14 fw-600">Others GYM Equipment Services</h2>
								</div>
							</div>
						</div>
						<div className="my-5">
							<div className="d-lg-flex d-block col-12 col-lg-6 m-auto">
								<button type='button' className="btn btn-primary rounded-pill me-2 mb-3 mb-lg-0 text-nowrap" data-bs-toggle="modal" data-bs-target="#BookServiceRequest">Book Service Request</button>
								<input type="text" name='' className='form-control rounded-pill me-2 mb-3 mb-lg-0' placeholder='Enter Service Request Number' />
								<button type='button' className="btn btn-outline-primary rounded-pill text-nowrap mb-3 mb-lg-0" data-bs-toggle="modal" data-bs-target="#BookServiceRequest">Track</button>
							</div>
							<p className='mt-4'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, quaerat nostrum? Quo consectetur atque amet placeat aut aspernatur libero, enim est soluta vel dolor odit nihil reiciendis quos perspiciatis molestiae.</p>
						</div>
					</div>
				</div>
			</section>
			<div className="modal fade" id="BookServiceRequest" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="BookServiceRequestLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5 fw-600" id="BookServiceRequestLabel">Enter Your Details</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
						</div>
						<div className="modal-body">
							<form action="">
								<div className="form-floating mb-3">
									<textarea className="form-control" placeholder="Enter Service Description" id="ServiceDescrip"></textarea>
									<label htmlFor="ServiceDescrip">Service Description</label>
								</div>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" id="FullName" placeholder="Enter Full Name" />
									<label htmlFor="FullName">Full Name</label>
								</div>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" id="MobileNumber" placeholder="Enter Mobile Number" />
									<label htmlFor="MobileNumber">Mobile Number</label>
								</div>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" id="EmailAddress" placeholder="Enter Email Address" />
									<label htmlFor="EmailAddress">Email Address</label>
								</div>
								<div className="row">
									<div className="col">
										<div className="form-floating mb-3">
											<select name="" id="" className="form-select">
												<option value="">Select</option>
											</select>
											<label htmlFor="EmailAddress">State</label>
										</div>
									</div>
									<div className="col">
										<div className="form-floating mb-3">
											<select name="" id="" className="form-select">
												<option value="">District</option>
											</select>
											<label htmlFor="EmailAddress">Select</label>
										</div>
									</div>
								</div>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" id="Address" placeholder="Enter Full Name" />
									<label htmlFor="Address">Address</label>
								</div>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" id="Pincode" placeholder="Enter PINcode" />
									<label htmlFor="Pincode">Pincode</label>
								</div>
							</form>
							<input type="checkbox" name="" id="AcceptTC" className='me-2'/>
							<label htmlFor="AcceptTC">I Agree <Link to=".">Terms & Conditions</Link></label>
						</div>
						<div className="modal-footer justify-content-between">
							<button type="button" className="btn btn-outline-primary rounded-pill" data-bs-dismiss="modal">Cancel</button>
							<button type="button" className="btn btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#ServicesRequestSucc">Submit Request</button>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="ServicesRequestSucc" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ServicesRequestSuccLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-body text-center">
							<i className="bi-check-circle-fill fs-36 text-success"></i>
							<div className='fw-600 mb-2'>Service Number:</div>
							<div className="form-control mb-3 bg-light"><span className="fs-24">948593</span></div>
							<h2 className='fs-22'>Your service request</h2>
							<p>Successfully Submited</p>
							<p>we sent you service request number to your email address and mobile number.</p>
							<button type="button" className="btn btn-primary rounded-pill px-4" data-bs-dismiss="modal">Ok</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ServiceVendors
