import React from 'react'

function GymManageSoftware() {
  	return (
    <>
		<section className='product-banner'>
			<div className="container">
				<div className="row d-flex align-items-center justify-content-between">
					<div className="col-12 col-lg-5 mb-lg-0 mb-4">
						<h1 className="fw-700 fs-28">GYM Management System</h1>
						<p>Our all-in-one system is designed to simplify gym management for you. Sign up in less than 30 seconds.</p>
						<p>Try out our 7 day risk free trial, upgrade at anytime, no questions, no hassle.</p>
						<button type="submit" className="btn btn-outline-primary rounded-pill">Free Trial</button>
					</div>
					<div className="col-12 col-lg-6">
						<div className="video-player">
							<iframe width="100%" height="350" src="https://www.youtube.com/embed/w9w50DqXU5o?si=nOE8iufgJrzme-JB" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div className="container my-5 d-flex align-items-center justify-content-center">
			<div className="row">
				<div className="w-100 text-center my-4">
					<h1 className='fs-24 fw-700 mb-3'>Flexible Pricing Plans</h1>
					<p className='mb-5'>Our GYM Entrance and Management System helps manage customers, employees, renewals, and payments. Whether you're a small startup or a large corporation, our annual subscription plans offer the best value for your investment.</p>
					<h1 className='fs-18 fw-700 mb-3'>What is included in every package?</h1>
					<p><i className='bi-check2 fs-18 primary-text'></i> Web application <i className='bi-check2 fs-18 primary-text'></i> Full loaded modules <i className='bi-check2 fs-18 primary-text'></i> 22+ Diet plans <i className='bi-check2 fs-18 primary-text'></i> Business page <i className='bi-check2 fs-18 primary-text'></i> Admin, Employee, and Customer Mobile Apps</p>
					
				</div>
				<div className="col-12 col-lg mb-3 mb-lg-0">
					<div className="card border-0 shadow-sm">
						<div className="card-body">
							<h2 className="fw-600 fs-22 primary-text">Silver</h2>
							<ul className="list-group list-group-flush mb-0">
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> 1 Branch</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Full loaded modules</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Business page</li>
								<li className="list-group-item px-0 pb-0 fw-700 fs-22 text-end"><i className='bi-currency-rupee'></i> 250/- <br /> <sub className='fs-10 fw-600 text-secondary'>Per Month</sub></li>
							</ul>
							<button type="submit" className="btn btn-primary w-100 mt-4 rounded-pill">Buy Now</button>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg mb-3 mb-lg-0">
					<div className="card border-0 shadow-sm">
						<div className="card-body">
							<h2 className="fw-600 fs-22 primary-text">Bronze</h2>
							<ul className="list-group list-group-flush mb-0">
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> 2 Branch</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Full loaded modules</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Business page</li>
								<li className="list-group-item px-0 pb-0 fw-700 fs-22 text-end"><i className='bi-currency-rupee'></i> 460/- <br /> <sub className='fs-10 fw-600 text-secondary'>Per Month</sub></li>
							</ul>
							<button type="submit" className="btn btn-primary w-100 mt-4 rounded-pill">Buy Now</button>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg mb-3 mb-lg-0">
					<div className="card border-0 shadow-sm">
						<div className="card-body">
							<h2 className="fw-600 fs-22 primary-text">Gold</h2>
							<ul className="list-group list-group-flush mb-0">
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> 5 Branch</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Full loaded modules</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Business page</li>
								<li className="list-group-item px-0 pb-0 fw-700 fs-22 text-end"><i className='bi-currency-rupee'></i> 650/- <br /> <sub className='fs-10 fw-600 text-secondary'>Per Month</sub></li>
							</ul>
							<button type="submit" className="btn btn-primary w-100 mt-4 rounded-pill">Buy Now</button>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg mb-3 mb-lg-0">
					<div className="card border-0 shadow-sm">
						<div className="card-body">
							<h2 className="fw-600 fs-22 primary-text">Platinum</h2>
							<ul className="list-group list-group-flush mb-0">
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Unlimited Branch</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Full loaded modules</li>
								<li className="list-group-item ps-0"><i className='bi-check2 text-success'></i> Business page</li>
								<li className="list-group-item px-0 pb-0 fw-700 fs-22 text-end"><i className='bi-currency-rupee'></i> 850/- <br /> <sub className='fs-10 fw-600 text-secondary'>Per Month</sub></li>
							</ul>
							<button type="submit" className="btn btn-primary w-100 mt-4 rounded-pill">Buy Now</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section className='product-features'>
			<div className="container">
				<div className="w-100 text-center my-4">
					<h1 className='fs-24 fw-700'>Explore GMS features</h1>
					<p>Bring your vision to life with AI and best-in-class video editing features.</p>
				</div>
				<div className="row my-5">
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-briefcase fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Hosting Free</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-people fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Access Control</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-currency-rupee fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Customized Dashboard</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-qr-code fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">QR Code Attendance</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-arrow-clockwise fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Customers Manager</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-arrow-clockwise fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Employee Manager</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-arrow-clockwise fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Track Expenses</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-arrow-clockwise fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">User Settings</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="text-center py-4">
							<i className="bi-arrow-clockwise fs-36 primary-text"></i>
							<h2 className="fw-600 fs-20">Business Page</h2>
							<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum optio qui nihil accusamus quaerat tempora</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  	)
}

export default GymManageSoftware
