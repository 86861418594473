import React from "react";
import MainRoute from "../routes/routes";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/900.css"

//import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './../assets/styles/themes.css';
import './../assets/styles/styles.css';
import QrReaderComponent from "./home";

const App = () => {
    return (
        <MainRoute />
    );
};

export default App;