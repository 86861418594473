import { UPDATE_DATA_CHILD1, UPDATE_DATA_CHILD2, UPDATE_DATA_CHILD3,LOGIN_USER_ACTION, RESET_STORE } from '../actions/authActions';

const initialState = {
  dataChild1: null,
  dataChild2: null,
  dataChild3: null,
  loginUser: null
};

const childReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATA_CHILD1:
      return {
        ...state,
        dataChild1: action.payload,
      };
    case UPDATE_DATA_CHILD2:
      return {
        ...state,
        dataChild2: action.payload,
      };
    case UPDATE_DATA_CHILD3:
      return {
        ...state,
        dataChild3: action.payload,
      };
    case LOGIN_USER_ACTION:
      return {
        ...state,
        loginUser: action.payload,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default childReducers;