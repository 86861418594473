import React,{ StrictMode } from "react";
import App from './containers/App';
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './store/store';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <GoogleOAuthProvider clientId='362325121757-7c5p570f09vktrnft9lfgojoei3dhjrl.apps.googleusercontent.com'>
    <Provider store={store}>
    <App />
  </Provider>
    </GoogleOAuthProvider>
  </StrictMode>
);
