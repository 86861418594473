import React from 'react'
import { Link } from 'react-router-dom'

function LandingFooter() {
	return (
		<>
			<footer>
				<div className="container-fluid">
					<div className="row px-2 px-lg-5">
						<div className="col-12 col-lg-3 col-xl">
							<h3 className='footer-title'>About GYM Entrance</h3>
							<ul className='footer-links'>
								<li><Link to="/about-us">About Us</Link></li>
								<li><Link to="/gym-management-software">GYM Management System</Link></li>
							</ul>
						</div>
						<div className="col-12 col-lg-3 col-xl">
							<h3 className='footer-title'>Listing Services</h3>
							<ul className='footer-links'>
								<li><Link to="/nutrition-consultant">Nutrition Consultant</Link></li>
								<li><Link to="">Supplements Market</Link></li>
								<li><Link to="/fitness-equipments">Fitness Equipment</Link></li>
								<li><Link to="/service-vendors">Service Vendors</Link></li>
							</ul>
						</div>
						<div className="col-12 col-lg-3 col-xl">
							<h3 className='footer-title'>Support</h3>
							<ul className='footer-links'>
								<li><Link to="/support">Support</Link></li>
								<li><Link to="">Call Back</Link></li>
								<li><Link to="">FAQs</Link></li>
								<li><Link to="">Our Community</Link></li>
							</ul>
						</div>
						<div className="col-12 col-lg-3 col-xl">
							<h3 className='footer-title'>Partner Programs</h3>
							<ul className='footer-links'>
								<li><Link to="">Affiliates</Link></li>
								<li><Link to="">Advertise with us</Link></li>
							</ul>
						</div>
						<div className="col-12 col-xl-4 col-lg">
							<img src={require('./../../assets/images/colorlogos.png')} className='d-block mb-3' width={'150px'} alt="" />
							<div className="foot-terms">
								<Link to="/terms-and-conditions">Terms of Conditions</Link>
								<Link to="/privacy-policy">Privacy Policy</Link>
								<Link to="/cookies-policy">Cookies Policy</Link>
							</div>
							<p>Copyright 2021 - 2024 OAMBOO Soft Consultants Pvt. Ltd. Operating Company. All Rights Reserved.</p>
							<div className="social-icons">
								<Link to="https://www.facebook.com/gymentrance"><i className="bi-facebook"></i></Link>
								<Link to=""><i className="bi-instagram"></i></Link>
								<Link to=""><i className="bi-youtube"></i></Link>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default LandingFooter