import { Link } from "react-router-dom"

function LandingPage() {

	return (
		<>
			<section className="d-flex align-items-center justify-content-center hero-banner-section">
				<div className="hero-title text-center col-12 col-lg-7">
					<h1 className="fs-28 fw-700"><span>GYM Entrance</span> Fitness Zone</h1>
					<p className="fs-16">Our all-in-one system is designed to simplify gym management for you.</p>
					<div className="input-group input-group-lg page-data-filter">
						<span className="input-group-text pe-0 ps-2 bg-white"><i className="bi-funnel fs-14"></i></span>
						<button className="btn dropdown-toggle border-end-0 fs-14 home-filter-drop d-flex align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Select Service</button>
						<ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0 dropstart">
							<li className="d-flex align-items-center px-2 py-2">
								<input type="radio" name="emp_status" className="me-2 mt-0 form-check-input" id="Nutrition" />
								<label htmlFor="Nutrition" className="w-100 mb-0 fw-400">Nutritionist</label>
							</li>
							<li className="d-flex align-items-center px-2 py-2">
								<input type="radio" name="emp_status" className="me-2 mt-0 form-check-input" id="Suppliement" />
								<label htmlFor="Suppliement" className="w-100 mb-0 fw-400">Supplements Market</label>
							</li>
							<li className="d-flex align-items-center px-2 py-2">
								<input type="radio" name="emp_status" className="me-2 mt-0 form-check-input" id="Fitnewss" />
								<label htmlFor="Fitnewss" className="w-100 mb-0 fw-400">Fitness Equipment's</label>
							</li>
							<li className="d-flex align-items-center px-2 py-2">
								<input type="radio" name="emp_status" className="me-2 mt-0 form-check-input" id="ServiceVender" />
								<label htmlFor="ServiceVender" className="w-100 mb-0 fw-400">Service Venders</label>
							</li>
						</ul>
						<input type="text" className="form-control fs-14" placeholder="Search with Customer name or Mobile number..." />
						<Link to="/profile-page" className="btn btn-primary search-btn"><i className="bi-search"></i></Link>
					</div>
				</div>
			</section>
		</>
	)
}

export default LandingPage
