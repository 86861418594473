
import { ApiResponseType } from '../../types/api';
import { axiosInstance } from './index';

export const accountService = {
    async createAccount(data: any) {
        const { data: responseData } = await axiosInstance.post(`gymaccount/createGymAccount`, data);
        return responseData as ApiResponseType;
    },
    async updateAccount(accountId: number, data: any) {
        const { data: responseData } = await axiosInstance.put(`gymaccount/updateGymAccount/${accountId}`, data);
        return responseData as ApiResponseType;
    },
    async getAccountById(accountId: number) {
        const { data: responseData } = await axiosInstance.get(`gymaccount/getGymAccountById/${accountId}`);
        return responseData as ApiResponseType;
    },
    async getGymAccountsByFilter(branchName: any,acctPayMode: any,acctPayType: any, acctPayTowards: any,acctPayAmount: any,payDate: any) {
        const { data: responseData } = await axiosInstance.get(`gymaccount/getGymAccountsByFilter/${branchName}/${acctPayMode}/${acctPayType}/${acctPayTowards}/${acctPayAmount}/${payDate}`);
        return responseData as ApiResponseType;
    },
    // async getGymAccountsByFilter(data: any) {
    //     const { data: responseData } = await axiosInstance.post(`gymaccount/getGymAccountsByFilter`, data);
    //     return responseData as ApiResponseType;
    // },
};