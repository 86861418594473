import { useOutlet } from 'react-router-dom';
import LandingHeader from './landing-includes/header';
import LoginPage from './landing-includes/login';
import RegisterPage from './landing-includes/register';
import SignUpPage from './landing-includes/sign-up';
import GmsRegForm from './landing-includes/gms-reg-form';
import GmsPackages from './landing-includes/gms-packages';
import PackageDetailsPopup from './landing-includes/package-details';
import { Link } from 'react-router-dom';
import { resetStore } from '../store/actions/authActions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import LandingFooter from './landing-includes/footer';

const MainHomePage = () => {
    const outlet = useOutlet();
    const dispatch = useDispatch();
	const [componentStack, setComponentStack] = useState<string[]>([]);
	const [activeComponent, setActiveComponent] = useState<string | null>("signUpPage");

	const showComponent = (component:string) => {
		setComponentStack((prevStack) => [...prevStack, activeComponent].filter(Boolean) as string[]);
		setActiveComponent(component);
	};

	const goBack = () => {
		if (componentStack.length > 0) {
			const lastComponent = componentStack.pop();
			setActiveComponent(lastComponent || null);
			setComponentStack([...componentStack]);
		}
	};

	const closePopup = () => {
		setActiveComponent("signUpPage");
		setComponentStack([]); // Clear the stack when closing the popup
		dispatch(resetStore());
	};
    
    return (
        <>
            <LandingHeader />
            <div>{outlet}</div>
            {/* <!-- Sign In Popup --> */}
			<div className="modal fade" id="SignInPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="SignIn" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered signin-dialog">
					<div className="modal-content br-10">
						<div className="modal-body p-0">
							<div className="d-flex">
								<div className="left-loginpop d-lg-inline-block d-none">

								</div>
								<div className="w-100">
									<LoginPage />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Sign Up Popup --> */}
			<div className="modal fade" id="Register" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="SignIn" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered signin-dialog">
					<div className="modal-content br-10">
						<div className="modal-body p-0">
							<div className="d-flex">
								<div className="left-loginpop d-lg-inline-block d-none">

								</div>
								{/* <div className="w-100">
									<SignUpPage />
									<RegisterPage />
									<GmsRegForm />
									<GmsPackages />
									<PackageDetailsPopup />
								</div>  */}
								<div className="w-100">
									{activeComponent === 'signUpPage' && <SignUpPage showComponent={showComponent} closePopup={closePopup} />}
									{activeComponent === 'register' && <RegisterPage showComponent={showComponent} closePopup={closePopup} />}
									{activeComponent === 'gmsRegForm' && <GmsRegForm showComponent={showComponent} closePopup={closePopup} />}
									{activeComponent === 'gmsPackages' && <GmsPackages showComponent={showComponent} closePopup={closePopup} />}
									{activeComponent === 'packageDetails' && <PackageDetailsPopup closePopup={closePopup} />}
									{/* Add other components as needed */}
									{activeComponent && activeComponent !== 'signUpPage' && (
										<div className="text-center m-auto px-3">
											<button type="button" className="btn btn-primary w-100 mb-3" onClick={goBack}>Back</button>
										</div>
									)}
									<p className="fs-14">Already have an account? <Link to={"."} data-bs-toggle="modal" data-bs-target="#SignInPopup">Sign In</Link></p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<LandingFooter />
        </>
    )
}

export default MainHomePage