import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import getValue from 'lodash/get';
import Select from 'react-select';
import TimePicker from 'react-time-picker';
import { EmployeeFormType } from '../../types/employee';
import moment from 'moment';
import { employeeService } from '../../lib/api/employee';
import { GetBranches } from '../../types/branch';
import { branchService } from '../../lib/api/branch';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import secureLocalStorage from 'react-secure-storage';
import { Customer } from '../../types/customer';

const genders = [{ name: 'Female', value: 1 }, { name: 'Male', value: 2 }, { name: 'Others', value: 3 }];
const empTypes = [{ name: 'Manager', value: 2 }, { name: 'Front Office', value: 3 }, { name: 'Trainer', value: 4 }, { name: 'Zumba Trainer', value: 5 }, { name: 'Attender', value: 6 }];
const shifts = [{ name: 'Morning Shift', value: 1 }, { name: 'Afternoon Shift', value: 2 }, { name: 'Evening Shift', value: 3 }];

const AddEditEmployee = () => {
    const navigate = useNavigate();
    const { empId } = useParams();
    const isEditMode = !!empId;
    const [branches, setBranches] = useState<GetBranches[]>([]);
    const [loginTime, setLoginTime] = useState('00:00');
    const [logoutTime, setLogoutTime] = useState('00:00');

    const [initialFormData, setInitialFormData] = useState({
        EmpName: '',
        EmpMobileNo: '',
        EmpEmailId: '',
        EmpGender: '',
        EmpPTPersent: '',
        EmpAddress: '',
        EmpJoinDate: moment().format('YYYY-MM-DD'),
        EmpType: '',
        EmpShift: '',
        EmpLoginTime: '',
        EmpLogoutTime: '',
        EmpGymBranchId: '',
    });

    const submitForm = useCallback(
        async (values: EmployeeFormType) => {
            try {
                if (isEditMode) {
                    const empData = {
                        ...values,
                        EmpGender: Number(values.EmpGender),
                        EmpPTPersent: Number(values.EmpPTPersent),
                        EmpType: Number(values.EmpType),
                        EmpShift: Number(values.EmpShift),
                        EmpGymBranchId: Number(values.EmpGymBranchId),
                    }
                    const { success, message } = await employeeService.updateGymEmployee(Number(empId), empData);
                    if (success) {
                        alert(message);
                        navigate("/employees");
                    }
                }
                else {
                    const custInfo = secureLocalStorage.getItem("custInfo");
					const customerInfo = {
						...custInfo as Customer
					}
                    const empData = {
                        ...values,
                        EmpGender: Number(values.EmpGender),
                        EmpPTPersent: Number(values.EmpPTPersent),
                        EmpType: Number(values.EmpType),
                        EmpShift: Number(values.EmpShift),
                        EmpGymBranchId: Number(values.EmpGymBranchId),
                        EmpGymId: customerInfo.CustId,
                        EmpStatus: 1
                    }
                    const { success, message } = await employeeService.addEmployee(empData);
                    if (success) {
                        alert(message);
                        navigate("/employees");
                    }
                }

            } catch (error) {
                console.log("error == ", error);
            }
        },
        []
    );

    const getBranchesByCustId = async () => {
        try {
            const custInfo = secureLocalStorage.getItem("custInfo");
            const customerInfo = {
                ...custInfo as Customer
            }
            const { data } = await branchService.getBranchesByCustId(Number(customerInfo.CustId));
            if (data.length) {
                setBranches(data);
            }
        } catch (err) {
            console.log('error from getAllBranches ', err);
        } finally {
            //setLoading(false);
        }
    };

    useEffect(() => {
        getBranchesByCustId();
        if (isEditMode && empId) {
            // Fetch branch data for editing
            const fetchEmployeeData = async () => {
                try {
                    const { data } = await employeeService.getGymEmployeeById(Number(empId));
                    if (data.length) {
                        const dataTest = {
                            ...data[0],
                            EmpJoinDate: moment(data[0].EmpJoinDate).format('YYYY-MM-DD'),
                        }
                        setLoginTime(data[0].EmpLoginTime);
                        setLogoutTime(data[0].EmpLogoutTime);
                        setInitialFormData(dataTest);
                    }
                } catch (err) {
                    console.log('error from getGymEmployeeById ', err);
                }
            };

            fetchEmployeeData();
        }
    }, [isEditMode, empId]);

    return (
        <>
            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <Link to={"/employees"} className="btn btn-hovers btn-sm"><i className="bi-arrow-left"></i> Back</Link>
                </div>
                <div>

                </div>
            </div>

            {/* <!-- Add Edit Employee Form --> */}
            <div className="col-12 col-lg-9 col-xl-6 m-auto">
                <h1 className="fs-18 fw-700 text-center w-100 mb-3">{isEditMode ? 'Edit Employee' : 'Add Employee'}</h1>
                <div className="dashboard-card bg-white text-start mb-5">
                    <Formik
                        onSubmit={submitForm}
                        enableReinitialize
                        validationSchema={getValidationSchema}
                        initialValues={initialFormData}
                    >
                        {(formikProps: FormikProps<EmployeeFormType>) => {
                            const { isValid, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm,errors } = formikProps;

                            return (
                                <>
                                {/* {!isValid && JSON.stringify(errors)} */}
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Name <span className="text-danger">*</span></label>
                                            <Field name="EmpName">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Emp Name" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Gender <span className="text-danger">*</span></label>
                                            <Field name="EmpGender">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedGenderOption = genders.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="Gender"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={genders}
                                                                placeholder="Select Gender"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedGenderOption}
                                                                className={`react-select ${error ? 'is-invalid' : ''}`}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Mobile Number <span className="text-danger">*</span></label>
                                            <Field name="EmpMobileNo">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter MobileNo" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Email</label>
                                            <Field name="EmpEmailId">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter EmailId" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">PT Percent <span className="text-danger">*</span></label>
                                            <Field name="EmpPTPersent">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter PT Persent" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Date of Joining</label>
                                            <Field name="EmpJoinDate">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="date"
                                                                value={field.value || ''}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Joining Date" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Employee Type <span className="text-danger">*</span></label>
                                            <Field name="EmpType">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedEmpTypeOption = empTypes.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="EmpType"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={empTypes}
                                                                placeholder="Select Employee Type"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedEmpTypeOption}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Shift</label>
                                            <Field name="EmpShift">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedShiftOption = shifts.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="Shift"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={shifts}
                                                                placeholder="Select Shift"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedShiftOption}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-6 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Login Time <span className="text-danger">*</span></label>
                                            <Field name="EmpLoginTime">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <TimePicker
                                                                onChange={(ev) => {
                                                                    ev && setLoginTime(ev);
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev);
                                                                }}
                                                                value={loginTime}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter PT Persent" /> */}
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-6 col-lg-6 mb-3 mb-lg-3">
                                            <label htmlFor="">Logout Time</label>
                                            <Field name="EmpLogoutTime">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <TimePicker
                                                                onChange={(ev) => {
                                                                    ev && setLogoutTime(ev);
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev);
                                                                }}
                                                                value={logoutTime}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter PT Persent" /> */}
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="">Address</label>
                                        <Field name="EmpAddress">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error = getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                return (
                                                    <>
                                                        <input
                                                            type="textarea"
                                                            value={field.value}
                                                            onChange={(ev) => {
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, ev.target.value);
                                                            }}
                                                            className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            placeholder="Enter Address" />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );

                                            }}
                                        </Field>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="">Branch</label>
                                        <Field name="EmpGymBranchId">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error =
                                                    getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                const selectedBranchOption = branches.find((option) => option.BranchId === field.value);
                                                return (
                                                    <>
                                                        <Select
                                                            name="EmpGymBranchId"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isSearchable
                                                            options={branches}
                                                            placeholder="Select Branch"
                                                            onChange={(selectedOption) => {
                                                                const { BranchId, BranchName } =
                                                                    selectedOption as GetBranches;
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, BranchId);
                                                            }}
                                                            value={selectedBranchOption}
                                                            getOptionLabel={(option) => option.BranchName}
                                                            getOptionValue={(option) => option.BranchId.toString()}
                                                        />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );
                                            }}
                                        </Field>
                                    </div>
                                    <div className="w-100 text-end">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                    </div>
                                </form>
                                </>
                            );
                        }}
                    </Formik>

                </div>
            </div>
        </>
    )
}

export default AddEditEmployee


const getValidationSchema = () =>
    objectYup().shape({
        EmpName: stringYup()
            .required('Name is required.'),
        EmpMobileNo: stringYup()
            .required('mobile number is required.')
            .min(10, 'MobileNo 10 numbers only'),
        EmpEmailId: stringYup()
            .email('Enter valid email')
            .required('Email is required.'),
        EmpGender: stringYup()
            .required('Gender is required.'),
        EmpPTPersent: stringYup()
            .required('EmpPTPersent is required.'),
        EmpAddress: stringYup()
            .required('Address is required.'),
        EmpJoinDate: stringYup()
            .required('Joining date is required.'),
        EmpType: stringYup()
            .required('Employee type is required.'),
        EmpGymBranchId: stringYup()
            .required('Branch is required.')
    });
