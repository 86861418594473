import React, { useState } from 'react'
import QrReaderComponent from '../containers/home'
import QrReader from '../containers/QrReader';
import { clientService } from '../lib/api/client';
import { Client, ClientType } from '../types/client';
import moment from 'moment';

const QRScanner = () => {
    const [openQr, setOpenQr] = useState<boolean>(false);
    const [scannedResult, setScannedResult] = useState<string | undefined>("");
    const [client, setClient] = useState<ClientType>();
    const [isRenewalDateValid, setIsRenewalDateValid] = useState(false);
    const [isVerificationMessage, setIsVerificationMessage] = useState("");
    const handleScanSuccess = async (result: string) => {
        setOpenQr(false);
        setScannedResult(result);
        console.log("result ", result);
        const qrReadParts = result.split('&');
        console.log("qrReadParts ", Number(qrReadParts[0]));
        try {
            const { data } = await clientService.getClientById(Number(qrReadParts[0]));
            if (data.length) {
                console.log("data ", data[0]);
                const clientRenewalDate = new Date(data[0].ClientRenewalDate);
                const isBeforeToday = isRenewalDateBeforeToday(clientRenewalDate);
                setIsRenewalDateValid(isBeforeToday);
                if (isBeforeToday) {
                    setIsVerificationMessage("Approved");
                }
                else {
                    setIsVerificationMessage("Verification Failed");
                }
                setClient(data[0]);
            }
            else {
                setIsRenewalDateValid(false);
                setIsVerificationMessage("Not Registered");
            }
        } catch (err) {
            setIsRenewalDateValid(false);
            setIsVerificationMessage("Not Registered");
            console.log('error from getClientById ', err);
        }
    };

    const isRenewalDateBeforeToday = (clientRenewalDate: any) => {
        // Get today's date
        const today = new Date();

        // Convert client renewal date to a Date object
        const renewalDate = new Date(clientRenewalDate);

        // Compare renewal date with today's date
        return renewalDate > today;
    };

    return (
        <>
            <div className="not-found">
                <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="col-12 col-lg-4 m-auto">
                        <div className="camera-session w-100 box-shadow-sm disable-bg mt-5 mt-lg-0">
                            {/* <span className="box-shadow-md"><i className="bi-camera fs-22"></i></span> */}
                            <div>
                                <button className='btn btn-primary d-flex align-items-center my-3 my-lg-0 m-auto' onClick={() => setOpenQr(!openQr)}>
                                    <i className='bi bi-camera fs-22 me-2'></i> {openQr ? "Close" : "Open"} QR Scanner
                                </button>
                                {openQr && <QrReader onScanSuccess={handleScanSuccess} />}
                            </div>
                        </div>
                    </div>
                    {isRenewalDateValid &&
                        <div className="col-12 col-lg-4 m-auto">
                            <div className="respons-session w-100 status-success-bg">
                                <div>
                                    <i className="bi-check-circle-fill"></i>
                                    <h2 className="fw-600 fs-22">Approved</h2>
                                    <p className="fs-18">{client?.ClientName}</p>
                                    <p className="fs-18 mb-0">Renewal Date</p>
                                    {<h2 className="fw-600 fs-22">{moment(client?.ClientRenewalDate).format('LL')}</h2> /* 22 April 2023 */}
                                </div>
                            </div>
                        </div>
                    }
                    {!isRenewalDateValid && isVerificationMessage != "" &&
                        <div className="col-12 col-lg-4 m-auto">
                            <div className="respons-session w-100 status-error-bg">
                                <div>
                                    <i className="bi-x-circle-fill"></i>
                                    <h2 className="fw-600 fs-22">{isVerificationMessage}</h2>
                                    {isVerificationMessage != "Not Registered" &&
                                        <div>
                                            <p className="fs-18">{client?.ClientName}</p><p className="fs-18 mb-0">Renewal Date</p>
                                            {<h2 className="fw-600 fs-22">{moment(client?.ClientRenewalDate).format('LL')}
                                            </h2>}
                                        </div>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default QRScanner
