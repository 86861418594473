import React from 'react'

const EditPayment = () => {
    return (
        <>
            <h1>Edit Payment</h1>
        </>
    )
}

export default EditPayment
