import React from 'react'
import { Link } from 'react-router-dom'

const Services = () => {
	return (
		<>
			<Link to={"/packages"} className="dashboard-card d-inline-block me-3 mb-4 nav-inactive">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="icon-circle me-2">
                        <i className="bi-bookmarks fs-22"></i>
                    </div>
                    <span className="fw-600 fs-16">Packages</span>
                </div>
            </Link>
            <Link to={"/services"} className="dashboard-card d-inline-block mb-4 nav-active">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="icon-circle me-2">
                        <i className="bi-gear fs-22"></i>
                    </div>
                    <span className="fw-600 fs-16">Services</span>
                </div>
            </Link>

            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="fw-700 fs-18 secondary-text">Services</h1>
                </div>
                <div>
                    <Link to={""} className="btn btn-hovers btn-sm"><i className="bi-plus-lg"></i> Add Service</Link>
                </div>
            </div>

            {/* <!-- List Item Starts --> */}
            <div className="card box-shadow-sm mb-3 div-list-items">
                <div className="card-body p-1 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                            <img src="../images/gear-avatar.png" alt="" />
                        </div>
                        <div>
                            <h2 className="fw-600 fs-14 mb-1">Service Name</h2>
                            <p className="mb-0 fs-12"><i className="bi-info-circle"></i> 1 Month</p>
                        </div>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Branch</h2>
                        <p className="mb-0 fs-14">Fitness Studio</p>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Duration</h2>
                        <p className="mb-0 fs-14">1 Months</p>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Created On</h2>
                        <p className="mb-0 fs-14">12th Jan 2023</p>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Service Charges</h2>
                        <p className="mb-0 fs-14"><i className="bi-currency-rupee"></i> 15,000</p>
                    </div>
                    <div className="d-flex px-2">
                        <button type="button" className="btn btn-hovers" title="Edit"><i className="bi-pencil"></i></button>
                        <button type="button" className="btn btn-hovers" title="Delete"><i className="bi-trash3"></i></button>
                    </div>
                </div>
            </div>
            {/* <!-- List Item End --> */}
		</>	
	)
}

export default Services
