import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { connect } from 'react-redux';
import { updateDataChild2 } from '../../store/actions/authActions';
import { customerService } from '../../lib/api/customer';

function SignUpPage({ showComponent, closePopup, dataChild2, updateDataChild2 }: any) {

  const signUp = useGoogleLogin({
    onSuccess: async respose => {
      try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            "Authorization": `Bearer ${respose.access_token}`
          }
        })
        const { success, data } = await customerService.validateEmail({ email: res.data.email });
        if (!success && data.length === 0) {
          const initialData = {
            CustGYMName: '',
            CustAddress: '',
            CustEmailId: res.data.email,
            CustContactNo: '',
            pageName: ''
          }
          updateDataChild2(initialData);
          showComponent('register');
        }
        else {
          alert("Login failed");
        }
        
      } catch (err) {
        console.log(err)
      }
    }
  });

  return (
    <>
      <div className="d-flex justify-content-end p-3">
        <button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" onClick={() => closePopup()} aria-label="Close"></button>
      </div>
      <div className="col-10 text-center m-auto pt-5">
        <div className="d-flex justify-content-center align-items-center mb-3">
          <button type="button" onClick={() => signUp()} className="btn-google"><i className="bi-google"></i> Sign up with Google</button>
        </div>
        <p className="fs-12">By clicking Sign Up or Sign In, you agree to our <Link to={"."}>Terms</Link> and that you have read our <Link to={"."}>Data Use Policy</Link>, including our <Link to={"."}>Cookie Use</Link>.</p>
        <div className="text-center my-3 fw-700 secondary-alt-text">Or</div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  dataChild2: state.children.dataChild2,
});

export default connect(mapStateToProps, { updateDataChild2 })(SignUpPage);