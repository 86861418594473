import React from 'react'
import { Link } from 'react-router-dom';

function GymProfilePage() {
    const onestar = { width: '15%' };
    const twostar = { width: '25%' };
    const threestar = { width: '35%' };
    const fourstar = { width: '55%' };
    const fivestar = { width: '75%' };
  return (
    <>
        <div className="container">
            <div className="banner-section my-4">
                <button type="button" className="btn btn-hovers fs-12"><i className="bi-camera"></i> Upload Banner</button>
            </div>
            <div className="dashboard-card d-lg-flex d-block justify-content-between bg-white mb-4">
                <div className="d-flex align-items-center mb-3 mb-lg-0">
                    <div className="avthar-box avthar-lg">
                        <i className="bi-buildings"></i>
                        <div className="upload-pic">
                            <i className="bi-camera text-white"></i>
                        </div>
                    </div>
                    <div className="ms-3 text-start">
                        <h1 className="fw-700 fs-22">Rocks Fitness Studio</h1>
                        <p className="fs-14 mb-2"><i className="bi-gender-ambiguous primary-alt-text"></i> Men and Women</p>
                        <p className="fs-14 mb-2"><i className="bi-geo-alt primary-alt-text"></i> Nagole, Hyderabad</p>
                        <p className="fs-14 mb-0"><i className="bi-telephone primary-alt-text"></i> +91 9381432416</p>
                    </div>
                </div>
                <div className="text-nowrap">
                    <button type="button" className="btn btn-hovers btn-sm" data-bs-toggle="modal" data-bs-target="#Enquire"><i className="bi-info-circle"></i> Enquire</button>
                    <button type="button" className="btn btn-hovers btn-sm" data-bs-toggle="modal" data-bs-target="#WriteReview"><i className="bi-chat-left-text"></i> Write a Review</button>
                    <button type="button" className="btn btn-hovers btn-sm"><i className="bi-share"></i> Share</button>
                </div>
            </div>

            <div className="row mb-0 mb-lg-4">
                <div className="col-lg-3 col-12 mb-lg-0 mb-3">
                    <div className="dashboard-card text-start bg-white p-4 info-card-height">
                        <h2 className="fs-16 fw-700">Customer Reviews</h2>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div>
                                <i className="fs-18 me-1 bi-star-fill text-warning"></i>
                                <i className="fs-18 me-1 bi-star-fill text-warning"></i>
                                <i className="fs-18 me-1 bi-star-fill text-warning"></i>
                                <i className="fs-18 me-1 bi-star-half text-warning"></i>
                                <i className="fs-18 me-1 bi-star text-warning"></i>
                            </div>
                            <span className="fs-14 fw-600">3 out of 5</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="fs-12 text-nowrap">5 Stars</div>
                            <div className="w-100 px-2">
                                <div className="progress prgress-height" role="progressbar">
                                    <div className="progress-bar bg-warning" style={fivestar}></div>
                                </div>
                            </div>
                            <div className="fs-12">56%</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="fs-12 text-nowrap">4 Stars</div>
                            <div className="w-100 px-2">
                                <div className="progress prgress-height" role="progressbar">
                                    <div className="progress-bar bg-warning" style={fourstar}></div>
                                </div>
                            </div>
                            <div className="fs-12">56%</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="fs-12 text-nowrap">3 Stars</div>
                            <div className="w-100 px-2">
                                <div className="progress prgress-height" role="progressbar">
                                    <div className="progress-bar bg-warning" style={threestar}></div>
                                </div>
                            </div>
                            <div className="fs-12">56%</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="fs-12 text-nowrap">2 Stars</div>
                            <div className="w-100 px-2">
                                <div className="progress prgress-height" role="progressbar">
                                    <div className="progress-bar bg-warning" style={twostar}></div>
                                </div>
                            </div>
                            <div className="fs-12">56%</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="fs-12 text-nowrap">1 Stars</div>
                            <div className="w-100 px-2">
                                <div className="progress prgress-height" role="progressbar">
                                    <div className="progress-bar bg-warning" style={onestar}></div>
                                </div>
                            </div>
                            <div className="fs-12">56%</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12 mb-lg-0 mb-3">
                    <div className="dashboard-card text-start bg-white p-4 info-card-height">
                        <h2 className="fs-16 fw-700 mb-3">Timings</h2>
                        <p className="fs-14 mb-2"><i className="bi-calendar3 primary-alt-text"></i> Monday to Saturday - 5:30 AM to 9:45 PM</p>
                        <p className="fs-14 mb-0"><i className="bi-calendar3 primary-alt-text"></i> Sunday - 5:30 AM to 10:30 AM</p>
                    </div>
                </div>
                <div className="col-lg-5 col-12 mb-lg-0 mb-3">
                    <div className="dashboard-card text-start bg-white p-4 info-card-height">
                        <h2 className="fs-16 fw-700 mb-3">Contact Us</h2>
                        <p className="fs-14 mb-2 d-flex"><i className="bi-geo-alt me-2 primary-alt-text"></i> 2-1-112/4 Venkatreddy nagar, Nagole, Hyderabad, Telangana 500068</p>
                        <p className="fs-14 mb-2 d-flex"><i className="bi-telephone me-2 primary-alt-text"></i> +91 9000992292</p>
                        <p className="fs-14 mb-2 d-flex"><i className="bi-envelope me-2 primary-alt-text"></i> world.cdcther@gmail.com</p>
                        <p className="fs-14 mb-0 d-flex"><i className="bi-globe me-2 primary-alt-text"></i> https://oamboogym.com</p>
                    </div>
                </div>
            </div>

            {/* <!-- GYM Overview Information --> */}
            <div className="row">
                <div className="col-12 col-lg-7">
                    <div className="dashboard-card bg-white text-start mb-4">
                        <h2 className="fw-700 fs-16">Services Overview</h2>
                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className="d-flex align-items-center my-2">
                                    <img src="images/locker.png" className="img-fluid me-2" alt="" />
                                    <h3 className="fs-14 fw-600">Lockers</h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex align-items-center my-2">
                                    <img src="images/washroom.png" className="img-fluid me-2" alt="" />
                                    <h3 className="fs-14 fw-600">Washrooms</h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex align-items-center my-2">
                                    <img src="images/dressing-room.png" className="img-fluid me-2" alt="" />
                                    <h3 className="fs-14 fw-600">Dressing  Room</h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex align-items-center my-2">
                                    <img src="images/steam-room.png" className="img-fluid me-2" alt="" />
                                    <h3 className="fs-14 fw-600">Steam Bath</h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex align-items-center my-2">
                                    <img src="images/lift-icon.png" className="img-fluid me-2" alt="" />
                                    <h3 className="fs-14 fw-600">Elevator</h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex align-items-center">
                                    <img src="images/ac-icon.png" className="img-fluid me-2" alt="" />
                                    <h3 className="fs-14 fw-600">Air Conditioner</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-card bg-white text-start mb-4 reiview-sticky">
                        <h2 className="fw-700 fs-16">Reviews</h2>
                        <div className="mt-4">
                            <div className="d-flex align-items-center mb-3">
                                <div className="avthar-box avthar-md">
                                    <i className="bi-person"></i>
                                </div>
                                <div className="ms-2 text-start">
                                    <h1 className="fw-600 fs-14 mb-2">Konda Vamshi Das</h1>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                            <i className="fs-18 bi-star-fill text-warning"></i>
                                            <i className="fs-18 bi-star-fill text-warning"></i>
                                            <i className="fs-18 bi-star-fill text-warning"></i>
                                            <i className="fs-18 bi-star-half text-warning"></i>
                                            <i className="fs-18 bi-star text-warning"></i>
                                        </div>
                                        <span className="fs-14 fw-600">4.5 out of 5</span>
                                    </div>
                                </div>
                            </div>
                            <p className="fs-14 mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam minus in id, deserunt dolorum earum delectus magnam hic nihil voluptate omnis iste est, iure, porro dicta tempore sint non laborum?</p>
                        </div>
                        <div className="mt-4">
                            <div className="d-flex align-items-center mb-3">
                                <div className="avthar-box avthar-md">
                                    <i className="bi-person"></i>
                                </div>
                                <div className="ms-2 text-start">
                                    <h1 className="fw-600 fs-14 mb-2">Konda Vamshi Das</h1>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                            <i className="fs-18 bi-star-fill text-warning"></i>
                                            <i className="fs-18 bi-star-fill text-warning"></i>
                                            <i className="fs-18 bi-star-fill text-warning"></i>
                                            <i className="fs-18 bi-star-half text-warning"></i>
                                            <i className="fs-18 bi-star text-warning"></i>
                                        </div>
                                        <span className="fs-14 fw-600">4.5 out of 5</span>
                                    </div>
                                </div>
                            </div>
                            <p className="fs-14 mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam minus in id, deserunt dolorum earum delectus magnam hic nihil voluptate omnis iste est, iure, porro dicta tempore sint non laborum?</p>
                        </div>
                        <div className="w-100 text-center mt-3">
                            <Link to="" className="btn btn-hovers text-decoration-none w-100 fs-14"> Show more <br /> <i className="bi-chevron-down"></i></Link>
                        </div>
                    </div>
                    <div className="dashboard-card bg-white text-start mb-4">
                        <h2 className="fw-700 fs-16">About Us</h2>
                        <p className="fs-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni voluptates omnis possimus quam quidem earum deleniti ab incidunt? Quam aperiam ratione magni non officiis asperiores expedita minima, nesciunt doloremque distinctio?</p>
                        <p className="fs-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni voluptates omnis possimus quam quidem earum deleniti ab incidunt? Quam aperiam ratione magni non officiis asperiores expedita minima, nesciunt doloremque distinctio?</p>
                    </div>
                </div>
                <div className="col-12 col-lg">
                    <div className="dashboard-card bg-white text-start mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h2 className="fs-14 secondary-alt-text mb-0">Post a photo and motivate others</h2>
                            <div className="d-flex align-items-center">
                                <i className="bi-images gray-600-text fs-28 me-3"></i>
                                <button className="btn btn-primary btn-lg rounded-pill">Post</button>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-card bg-white text-start mb-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex align-items-center">
                                <div className="avthar-box avthar-md">
                                    <i className="bi-person"></i>
                                </div>
                                <div className="ms-3 text-start">
                                    <h1 className="fw-600 fs-14 mb-1">Konda Vamshi Das</h1>
                                    <span className="fs-12">1 Month Ago</span>
                                </div>
                            </div>
                            <div className="flex-shrink-1">
                                <button type="button" className="btn btn-hovers btn-sm" title="Edit Post"><i className="bi-pencil"></i></button>
                                <button type="button" className="btn btn-hovers btn-sm" title="Delete Post"><i className="bi-trash3"></i></button>
                            </div>
                        </div>
                        <img src="images/demo2.jpg" className="img-fluid rounded-4" alt="" />
                        <div className="d-flex my-3 align-items-center">
                            <button type="button" className="btn btn-hovers btn-sm" title="Edit Post">
                                <span className="me-1">256</span> <img src="images/like-thumbe.svg" width="18px" alt="" />
                            </button>
                            <button type="button" className="btn btn-hovers btn-sm d-flex align-items-center" title="Delete Post">
                                <span className="me-1">150</span> <img src="images/hand-clap.svg" width="22px" alt="" />
                            </button>
                        </div>
                        <p className="fs-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut doloremque praesentium quis aperiam, natus architecto delectus corporis impedit illo</p>
                        <div className="d-flex mt-3 align-items-center justify-content-end">
                            <button type="button" className="btn btn-hovers btn-sm" title="Edit Post">
                                <img src="images/like-thumbe.svg" width="18px" alt="" /> <span className="me-1">Like</span>
                            </button>
                            <button type="button" className="btn btn-hovers btn-sm d-flex align-items-center" title="Delete Post">
                                <img src="images/hand-clap.svg" width="22px" alt="" /> <span className="me-1">Appreciate</span>
                            </button>
                        </div>
                    </div>
                    <div className="dashboard-card bg-white text-start mb-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex align-items-center">
                                <div className="avthar-box avthar-md">
                                    <i className="bi-person"></i>
                                </div>
                                <div className="ms-3 text-start">
                                    <h1 className="fw-600 fs-14 mb-1">Konda Vamshi Das</h1>
                                    <span className="fs-12">1 Month Ago</span>
                                </div>
                            </div>
                            <div className="flex-shrink-1">
                                <button type="button" className="btn btn-hovers btn-sm" title="Edit Post"><i className="bi-pencil"></i></button>
                                <button type="button" className="btn btn-hovers btn-sm" title="Delete Post"><i className="bi-trash3"></i></button>
                            </div>
                        </div>
                        <img src="images/demo2.jpg" className="img-fluid rounded-4" alt="" />
                        <div className="d-flex my-3 align-items-center">
                            <button type="button" className="btn btn-hovers btn-sm" title="Edit Post">
                                <span className="me-1">256</span> <img src={require('../assets/images/like-thumbe.svg')} width="18px" alt="" />
                            </button>
                            <button type="button" className="btn btn-hovers btn-sm d-flex align-items-center" title="Delete Post">
                                <span className="me-1">150</span> <img src={require("../assets/images/hand-clap.svg")} width="22px" alt="" />
                            </button>
                        </div>
                        <p className="fs-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut doloremque praesentium quis aperiam, natus architecto delectus corporis impedit illo</p>
                        <div className="d-flex mt-3 align-items-center justify-content-end">
                            <button type="button" className="btn btn-hovers btn-sm" title="Edit Post">
                                <img src={require('../assets/images/like-thumbe.svg')} width="18px" alt="" /> <span className="me-1">Like</span>
                            </button>
                            <button type="button" className="btn btn-hovers btn-sm d-flex align-items-center" title="Delete Post">
                                <img src={require("../assets/images/hand-clap.svg")} width="22px" alt="" /> <span className="me-1">Appreciate</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Enquire Popup --> */}
        <div className="modal fade" id="Enquire" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Enquire" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered signin-dialog">
                <div className="modal-content br-10">
                    <div className="modal-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <h2 className='fw-600 fs-18'>Send Enquire</h2>
                            <button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="FullName">Fullname <span className='text-danger'>*</span></label>
                            <input type="text" name="fullname" id='FullName' placeholder='Fullname' />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="FullName">Enquiring for <span className='text-danger'>*</span></label>
                            <select name="" id="" className="form-select">
                                <option value="">Select</option>
                            </select>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="">Mobile Number <span className='text-danger'>*</span></label>
                                <input type="text" name="" id="" placeholder='' />
                            </div>
                            <div className="col">
                                <label htmlFor="">Email Address <span className='text-danger'>*</span></label>
                                <input type="text" name="" id="" placeholder='' />
                            </div>
                        </div>
                        <div className="mb-3">
                            <textarea name="" id="" className='form-control' placeholder='Write comment here..'></textarea>
                        </div>
                        <div className="w-100 text-end">
                            <button type='button' className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Write a Review Popup --> */}
        <div className="modal fade" id="WriteReview" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="WriteReview" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered signin-dialog">
                <div className="modal-content br-10">
                    <div className="modal-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <h2 className='fw-600 fs-18'>Write a Review</h2>
                            <button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="d-flex align-content-center mb-3">
                            <label htmlFor="FullName">3</label>
                            <i className="bi-star text-warning fs-22"></i>
                            <i className="bi-star text-warning fs-22"></i>
                            <i className="bi-star text-warning fs-22"></i>
                            <i className="bi-star text-warning fs-22"></i>
                            <i className="bi-star-half text-warning fs-22"></i>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Comment">Comment <span className='text-danger'>*</span></label>
                            <textarea name="" id="" className='form-control' rows={5} placeholder='Write comment here..'></textarea>
                        </div>
                        <div className="w-100 text-end">
                            <button type='button' className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default GymProfilePage