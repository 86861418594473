
import { ApiResponseType } from '../../types/api';
import { axiosInstance } from './index';

export const clientService = {
    async createClient(data: any) {
        const { data: responseData } = await axiosInstance.post(`clients/createClient`, data);
        return responseData as ApiResponseType;
    },
    async getGymClientsByFilter( branchName: any,clientStatus: any, clientName: any,clientMobileNo: any) {
        const { data: responseData } = await axiosInstance.get(`clients/getGymClientsByFilter/${branchName}/${clientStatus}/${clientName}/${clientMobileNo}`);
        return responseData as ApiResponseType;
    },
    async updateClient(clientId: number, data: any) {
        const { data: responseData } = await axiosInstance.put(`clients/updateClient/${clientId}`, data);
        return responseData as ApiResponseType;
    },
    async getClientById(empId: number) {
        const { data: responseData } = await axiosInstance.get(`clients/getClientById/${empId}`);
        return responseData as ApiResponseType;
    },
};