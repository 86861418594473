import React from 'react';
import { Link } from 'react-router-dom';

function LandingHeader() {
    return (
        <>
            <div className="alert alert-warning mb-0 text-center rounded-0">
                <h3 className="fs-18 mb-0 text-danger"><i className='bi-info-circle-fill'></i> Website is Under Development. We will be on live ASAP.</h3>
            </div>
            <header>
                <nav className="navbar navbar-expand-xl">
                    <div className="container-fluid">
                        <Link className="navbar-brand home-brand" to={'.'}>
                            <img src={require('./../../assets/images/colorlogos.png')} alt="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#HomeNav" aria-controls="HomeNav" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="HomeNav" aria-labelledby="HomeNavLabel">
                            <div className="offcanvas-header">
                                <Link className="navbar-brand home-brand" to={'.'}>
                                    <img src={require('./../../assets/images/colorlogos.png')} alt="" />
                                </Link>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body align-items-center justify-content-between">
                                <ul className="navbar-nav justify-content-start flex-grow-1 pe-3 home-main-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" to={'/'}>Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/gym-management-software"}>GYM Management Software</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/fitness-equipments"}>Fitness Shop</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/nutrition-consultant"}>Nutrition Consultant</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to={'/service-vendors'}>Service Vendors</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'/support'}>Support</Link>
                                    </li>
                                </ul>
                                <div>
                                    <button type="button" className="btn btn-sm btn-outline-primary me-3 px-4 rounded-pill" data-bs-toggle="modal" data-bs-target="#SignInPopup">Sign In</button>
                                    <button type="button" className="btn btn-sm btn-primary px-4 rounded-pill" data-bs-toggle="modal" data-bs-target="#Register">Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default LandingHeader