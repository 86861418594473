import { combineReducers } from 'redux';
import childReducers from './authReducer';
import customer from './customer';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  children: childReducers,
  customer
  // Add more reducers if needed
});

export default rootReducer;