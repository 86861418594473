import React, { useCallback,useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import getValue from 'lodash/get';
import { GymFormType } from '../../types/gym';
import { connect } from 'react-redux';
import { updateDataChild2 } from '../../store/actions/authActions';
const initialData = {
    CustGYMName: '',
    CustAddress: '',
    CustEmailId: '',
    CustContactNo: '',
    pageName:''
}

function GmsRegForm({ showComponent, closePopup, dataChild2, updateDataChild2 } : any) {   
    const [initialFormData, setInitialFormData] = useState(dataChild2== null?initialData:dataChild2);
    

    const submitForm = useCallback(
        async (values: GymFormType) => {
            updateDataChild2(values);
            return showComponent('gmsPackages');
        },
        []
    );
    return (
        <>
            <div className="d-flex align-items-center justify-content-between p-3">
                <div>
                    <h2 className="fw-600 fs-18 mb-0">GYM Management System</h2>
                    <p className="fs-10 mb-0">Give us more information about you.</p>
                </div>
                <button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" onClick={() => closePopup()} aria-label="Close"></button>
            </div>
            <div className="text-center m-auto px-3">

                <Formik
                    onSubmit={submitForm}
                    enableReinitialize
                    validationSchema={getValidationSchema}
                    initialValues={initialFormData}
                >
                    {(formikProps: FormikProps<GymFormType>) => {
                        const { isValid, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm } = formikProps;

                        return (
                            <><form onSubmit={handleSubmit}>
                                <div className="input-group business-options mb-3">
                                    <div className="input-group-text icon-box"><i className="bi-buildings"></i></div>
                                    <Field name="CustGYMName">
                                        {(fieldProps: FieldProps) => {
                                            const { field, form } = fieldProps;
                                            const error = getValue(form.touched, field.name) &&
                                                getValue(form.errors, field.name);
                                            return (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={field.value}
                                                        onChange={(ev) => {
                                                            setFieldTouched(field.name);
                                                            setFieldValue(field.name, ev.target.value);
                                                        }}
                                                        className={`form-control ${error ? 'is-invalid' : ''}`}
                                                        placeholder="Enter GYM Name" />
                                                    {error && <small className="text-danger">{error.toString()}</small>}
                                                </>
                                            );

                                        }}
                                    </Field>
                                </div>
                                <div className="input-group business-options mb-3">
                                    <div className="input-group-text icon-box"><i className="bi-geo-alt"></i></div>
                                    <Field name="CustAddress">
                                        {(fieldProps: FieldProps) => {
                                            const { field, form } = fieldProps;
                                            const error = getValue(form.touched, field.name) &&
                                                getValue(form.errors, field.name);
                                            return (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={field.value}
                                                        onChange={(ev) => {
                                                            setFieldTouched(field.name);
                                                            setFieldValue(field.name, ev.target.value);
                                                        }}
                                                        className={`form-control ${error ? 'is-invalid' : ''}`}
                                                        placeholder="Enter Address" />

                                                    {error && <small className="text-danger">{error.toString()}</small>}
                                                </>
                                            );
                                        }}
                                    </Field>
                                </div>
                                <div className="input-group business-options mb-3">
                                    <div className="input-group-text icon-box"><i className="bi-at"></i></div>
                                    <Field name="CustEmailId">
                                        {(fieldProps: FieldProps) => {
                                            const { field, form } = fieldProps;
                                            const error = getValue(form.touched, field.name) &&
                                                getValue(form.errors, field.name);
                                            return (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={field.value}
                                                        disabled
                                                        onChange={(ev) => {
                                                            setFieldTouched(field.name);
                                                            setFieldValue(field.name, ev.target.value);
                                                        }}
                                                        className={`form-control ${error ? 'is-invalid' : ''}`}
                                                        placeholder="Enter Email" />

                                                    {error && <small className="text-danger">{error.toString()}</small>}
                                                </>
                                            );
                                        }}
                                    </Field>
                                </div>
                                <div className="input-group business-options mb-3">
                                    <div className="input-group-text icon-box"><i className="bi-phone"></i></div>
                                    <Field name="CustContactNo">
                                        {(fieldProps: FieldProps) => {
                                            const { field, form } = fieldProps;
                                            const error = getValue(form.touched, field.name) &&
                                                getValue(form.errors, field.name);
                                            return (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={field.value}
                                                        onChange={(ev) => {
                                                            setFieldTouched(field.name);
                                                            setFieldValue(field.name, ev.target.value);
                                                        }}
                                                        className={`form-control ${error ? 'is-invalid' : ''}`}
                                                        placeholder="Enter Mobile Number" />

                                                    {error && <small className="text-danger">{error.toString()}</small>}
                                                </>
                                            );
                                        }}
                                    </Field>

                                </div>
                                <div className="input-group business-options mb-3">
                                    <div className="input-group-text icon-box"><i className="bi-window"></i></div>
                                    <Field name="pageName">
                                        {(fieldProps: FieldProps) => {
                                            const { field, form } = fieldProps;
                                            return (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={field.value}
                                                        onChange={(ev) => {
                                                            setFieldTouched(field.name);
                                                            setFieldValue(field.name, ev.target.value);
                                                        }}
                                                        className={`form-control`}
                                                        placeholder="Page Name" />
  </>
                                            );
                                        }}
                                    </Field>
                                    </div>
                                <button type="submit" className="btn btn-primary w-100 mb-3" disabled={isSubmitting}>
                                    
                                     Continue
                                    </button>
                            </form>
                                
                            </>
                        );
                    }}
                </Formik>
                {/* <p className="fs-14">Already have an account? <Link to={"."}>Sign In</Link></p> */}
            </div>
        </>
    )
}
const mapStateToProps = (state:any) => ({
    dataChild2: state.children.dataChild2,
  });
  
  export default connect(mapStateToProps, { updateDataChild2 })(GmsRegForm);

const getValidationSchema = () =>
    objectYup().shape({
        CustGYMName: stringYup()
            .required('Gym is required.'),
        CustAddress: stringYup()
            .required('Address is required.'),
        CustEmailId: stringYup()
            .email('Enter valid email')
            .required('Email is required.'),
        CustContactNo: stringYup()
            .required('ContactNo is required.')
            .min(10, 'Contact No 10 numbers only'),
    });