import React from 'react'
import { Link } from 'react-router-dom'

export default function Dashboard() {
	return (
	<>
		{/* <!-- Page Title --> */}
		<div className="d-flex align-items-center justify-content-between">
			<div>
				<h1 className="fw-700 fs-18 secondary-text">My Dashboard</h1>
			</div>
			<div>
				<select name="" id="" className="form-select">
					<option value="">Select Branch</option>
					<option value="">Nagole</option>
					<option value="">Kukatpally</option>
				</select>
			</div>
		</div>

		{/* <!-- Dashboard Matrics Cards --> */}
		<div className="row my-3">
			<div className="col-12 col-xl-6">
				<div className="row">
					<div className="col-4 col-lg">
						<div className="dashboard-card status-success-bg">
							<div className="circle-icon">
								<i className="bi-people fs-32 primary-text"></i>
							</div>
							<h2 className="fs-24 fw-900 mt-3">340</h2>
							<div className="fs-600 fw-600">Active Customers</div>
							<button type="button" className="btn btn-outline-primary btn-sm fit-content my-3">View All</button>
						</div>
					</div>
					<div className="col-4 col-lg">
						<div className="dashboard-card status-error-bg">
							<div className="circle-icon">
								<i className="bi-arrow-repeat fs-32 primary-text"></i>
							</div>
							<h2 className="fs-24 fw-900 mt-3">140</h2>
							<div className="fs-600 fw-600">Renewals</div>
							<button type="button" className="btn btn-outline-primary btn-sm fit-content my-3">View All</button>
						</div>
					</div>
					<div className="col-4 col-lg">
						<div className="dashboard-card status-warning-bg">
							<div className="circle-icon">
								<i className="bi-briefcase fs-32 primary-text"></i>
							</div>
							<h2 className="fs-24 fw-900 mt-3">20</h2>
							<div className="fs-600 fw-600">Employees</div>
							<button type="button" className="btn btn-outline-primary btn-sm fit-content my-3">View All</button>
						</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 col-lg mb-3 mb-xl-0">
						<div className="rounded-4 px-3 bg-white d-flex align-items-center justify-content-between py-4">
							<div className="fw-600 fs-16 secondary-alt-text">Today Income</div>
							<div className="fw-600 fs-16"><i className="bi-currency-rupee"></i> 33,345</div>
						</div>
					</div>
					<div className="col-12 col-lg mb-3 mb-xl-0">
						<div className="rounded-4 px-3 bg-white d-flex align-items-center justify-content-between py-4">
							<div className="fw-600 fs-16 secondary-alt-text">Current Month Income</div>
							<div className="fw-600 fs-16"><i className="bi-currency-rupee"></i> 1,33,345</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-12 col-xl ps-lg-3 ps-3 ps-xl-4">
				<div className="dashboard-card bg-white">
					<div className="card-body">
						<div className="d-flex align-items-center justify-content-between">
							<h2 className="fw-700 fs-16 mb-0">Employee Attendances</h2>
							<Link to={"."} className="btn btn-link btn-sm">View All</Link>
						</div>
						<table className="table table-striped table-hover fs-14 mb-0 text-start">
							<thead>
								<tr>
									<th>EMP ID</th>
									<th>Name</th>
									<th>Designation</th>
									<th>Login</th>
									<th>Logout</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>Mark</td>
									<td>Otto</td>
									<td>@mdo</td>
									<td>@mdo</td>
								</tr>
								<tr>
									<td>2</td>
									<td>Jacob</td>
									<td>tdornton</td>
									<td>@fat</td>
									<td>@fat</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Larry the Bird</td>
									<td>@twitter</td>
									<td>@twitter</td>
									<td>@twitter</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Larry the Bird</td>
									<td>@twitter</td>
									<td>@twitter</td>
									<td>@twitter</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Larry the Bird</td>
									<td>@twitter</td>
									<td>@twitter</td>
									<td>@twitter</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Larry the Bird</td>
									<td>@twitter</td>
									<td>@twitter</td>
									<td>@twitter</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Larry the Bird</td>
									<td>@twitter</td>
									<td>@twitter</td>
									<td>@twitter</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="rounded-4 p-3 bg-white mt-4">
					<div className="d-flex align-items-center">
						<div className="flex-shrink-1 pe-3">
							<img src="../images/avthar-sm.png" alt=""/>
						</div>
						<div className="text-start w-100">
							<h4 className="fs-14 fw-600 mb-0">Konda Vamshi Das</h4>
							<span className="fs-12">Personal Trainner - Login 5:30 AM</span>
							<div className="d-block">
								<div className="d-inline-block status-success-bg rounded-pill fs-10 px-2">Login</div>
							</div>
						</div>
						<div className="flex-shrink-1 ps-3">
							<i className="bi-circle-fill status-success-text"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="d-flex my-3">
			<div className="col-6">
				<img src={require('../assets/images/image_8.png')} className="w-100" alt="" />
			</div>
			<div className="col-6 ps-4">
				<img src={require('../assets/images/image_7.png')} className="w-100" alt="" />
			</div>
		</div>
	</>
	)
}
