export const urlPrefix = '/';

export const routes = {
    main: `${urlPrefix}`,
    home: `${urlPrefix}`,
    profilepage: `${urlPrefix}profile-page`,
    dashboard: `${urlPrefix}dashboard`,
    employees: `${urlPrefix}employees`,
    clients: `${urlPrefix}clients`,
    qrScanner: `${urlPrefix}qr-scanner`,
    accounts: `${urlPrefix}accounts`,
    branches: `${urlPrefix}branches`,
    settings: `${urlPrefix}settings`,
    gymmanagesoftware: `gym-management-software`,
    fitnessequipments: `fitness-equipments`,
    nutritionconsultant: `nutrition-consultant`,
    servicevendors: `service-vendors`,
    support: `support`,
    privacypolicy: `privacy-policy`,
    cookiespolicy: `cookies-policy`,
    termsandconditions: `terms-and-conditions`,
    aboutus: `about-us`,
    nutritionsearchresults: `nutrition-search-results`,
    consultantpage: `consultant-page`,
};