import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Table from "../../components/Table";
import { branchService } from '../../lib/api/branch';
import { Branch } from '../../types/branch';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import secureLocalStorage from 'react-secure-storage';
import { Customer } from '../../types/customer';

const BranchesList = () => {
    const fetchIdRef = useRef(0);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0);
    const [branches, setBranches] = useState<Branch[]>([]);
    const [branchName, setBranchName] = useState<string | null>(null);
    const [branchContactNo, setBranchContactNo] = useState<string | null>(null);
    const [statusValue, setStatusValue] = useState(null);
    const [statusData, setStatusData] = useState([
        { id: 1, name: 'Pending', value: 1, isChecked: false },
        { id: 2, name: 'Active', value: 2, isChecked: false },
        { id: 3, name: 'Relieved', value: 3, isChecked: false },
        { id: 4, name: 'Archive', value: 4, isChecked: false }
    ]);

    const [initialBranchCount, setInitialBranchCount] = useState(0);
    const [remainingQuota, setRemainingQuota] = useState(0);

    //const customerData = useSelector((state: RootState) => state.customer.profile);
    // Function to render the "Add Branch" links based on Quata value


    const fetchData = useCallback(async ({ pageSize, pageIndex }: any) => {

        const fetchId = ++fetchIdRef.current

        const { success, data } = await branchService.getGymBranchesByFilter(statusValue, branchName, branchContactNo);
        if (success) {
            setLoading(true);

            setTimeout(() => {
                // Only update the data if this is the latest fetch
                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex
                    const endRow = startRow + pageSize
                    setBranches(data.slice(startRow, endRow))

                    // Your server could send back total page count.
                    // For now we'll just fake it, too
                    setPageCount(Math.ceil(data.length / pageSize));

                    setLoading(false)
                }
            }, 1000);
        }
    }, [statusValue, branchName, branchContactNo]);

    const columns = [
        {
            Header: "Name",
            accessor: "BranchName"
        },
        {
            Header: "Contact No",
            accessor: "BranchContactNo"
        },
        {
            Header: "Created On",
            accessor: "BranchCreated",

        },
        {
            Header: "Address",
            accessor: "BranchAddress",
        },
        {
            Header: "Status",
            accessor: "BranchStatus",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <>
                        {value === 2 && <span className="alert alert-success rounded-pill py-0 px-2 fs-12">Active</span>}
                        {value === 1 && <span className="alert alert-warning rounded-pill py-0 px-2 fs-12">Pending</span>}
                    </>
                );
            }
        },
        {
            Header: "Action",
            Cell: (cell: any) => (
                <>
                    <button type="button" className="btn btn-hovers" title="Edit"><i className="bi-pencil"
                        onClick={() => {
                            navigate(`/add-edit-branch/${Number(cell.row.original.BranchId)}`)
                        }

                        }></i></button>
                    <button type="button" className="btn btn-hovers" title="Delete"><i className="bi-trash3"></i></button>
                </>
            )
        }
    ];

    const handleChecked = (e: any) => {
        setStatusValue(e.target.value);
        const res = statusData.map((d) => {
            if (d.value.toString() === e.target.value.toString()) {
                return { ...d, isChecked: !d.isChecked };
            }
            else {
                return { ...d, isChecked: false };
            }
        });

        const eamtyArray = res.filter((d) => {
            return d.isChecked === true
        });
        if (eamtyArray.length === 0) {
            setStatusValue(null);
        }
        setStatusData(res);
    };

    useEffect(() => {
        const fetchBranchData = async () => {
			try {
                const custInfo = secureLocalStorage.getItem("custInfo");
                const customerInfo = { 
                    ...custInfo as Customer
                }
                if (customerInfo) {
                    const { success, data } = await branchService.getBranchesByCustId(customerInfo.CustId);
                    if(success)
                    {
                        setRemainingQuota(customerInfo.CustBranchQouta - data.length);
                    }
                }
			} catch (err) {
			  console.log('error from getClientById ', err);
			}
		  };
	
		  fetchBranchData();
    }, []);

    return (
        <>
            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="fw-700 fs-18 secondary-text">Branches</h1>
                </div>
                <div>
                    {/* Conditionally render the "Add Branch" button based on remaining quota */}
                    {remainingQuota > 0 ? (
                        // <button onClick={handleAddBranch} className="btn btn-hovers btn-sm">
                        //     <i className="bi-plus-lg"></i> Add Branch
                        // </button>
                        <Link to={"/add-edit-branch"} className="btn btn-hovers btn-sm"><i className="bi-plus-lg"></i>Add Branch</Link>
                    ) : (
                        <button disabled className="btn btn-hovers btn-sm">
                            <i className="bi-plus-lg"></i> Add Branch
                        </button>
                    )}
                </div>
            </div>

            {/* <!-- Page Data Filter --> */}
            <div className="input-group input-group-lg page-data-filter border-0 shadow-sm">
                <span className="input-group-text pe-0 ps-2 border-0"><i className="bi-funnel"></i></span>
                <input type="text" className="form-control fs-14 border-0"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value != null && value != "") {
                            setBranchName(value);
                            setBranchContactNo(value);
                        }
                        else {
                            setBranchName(null);
                            setBranchContactNo(null);
                        }

                    }}
                    placeholder="Search with Branch name or Contact number.." />
                <button className="btn btn-outline-secondary dropdown-toggle border-0 fs-14" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Status</button>
                <ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0">
                    {statusData.map((d: any) => (
                        <div className="p-2">
                            <label htmlFor={`status-${d.id}`}>
                                <input
                                    id={`status-${d.id}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={d.isChecked}
                                    name="statusGroup"
                                    value={d.value}
                                    onChange={handleChecked}
                                />
                                <span className="form-check-label ms-2 fw-600">{d.name}</span>
                            </label>
                        </div>
                    ))}
                </ul>
                <button type="button" className="btn btn-primary search-btn border-0"><i className="bi-search"></i></button>
            </div>

            {/* <!-- List Item Starts --> */}
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <Table
                        columns={columns}
                        data={branches}
                        loading={loading}
                        pageCount={pageCount}
                        fetchData={fetchData}
                    />
                </div>
            </div>
            {/* <!-- List Item End --> */}
        </>
    )
}

export default BranchesList
