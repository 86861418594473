
import { ApiLoginResponseType, ApiResponseType } from '../../types/api';
import { axiosInstance } from './index';

export const customerService = {
    async validateEmail(data: any) {
        const { data: responseData } = await axiosInstance.post(`oamboocustomers/validateEmail`, data);
        return responseData as ApiLoginResponseType;
    },
    async getCustomerList() {
        const { data: responseData } = await axiosInstance.get(`oamboocustomers/getAllOambooCustors`);
        return responseData as ApiResponseType;
    },
    async createCustomer(data: any) {
        const { data: responseData } = await axiosInstance.post(`oamboocustomers/createOambooCustor`, data);
        return responseData as ApiResponseType;
    },
    async getOambooCustorsByFilter( branchName: any,custStatus: any, custName: any,custContactNo: any) {
        const { data: responseData } = await axiosInstance.get(`oamboocustomers/getOambooCustorsByFilter/${branchName}/${custStatus}/${custName}/${custContactNo}`);
        return responseData as ApiResponseType;
    },
};