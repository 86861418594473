import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import getValue from 'lodash/get';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import { GetBranches } from '../../types/branch';
import { branchService } from '../../lib/api/branch';
import { ClientFormType } from '../../types/client';
import moment from 'moment';
import { clientService } from '../../lib/api/client';
import { routes } from '../../routes/routes-names';
import { employeeService } from '../../lib/api/employee';
import { Employee } from '../../types/employee';
import secureLocalStorage from 'react-secure-storage';
import { Customer } from '../../types/customer';

const genders = [{ name: 'Female', value: 1 }, { name: 'Male', value: 2 }, { name: 'Others', value: 3 }];
const packagesList = [{ name: '1 Month', value: 1 }, { name: '2 Months', value: 2 }, { name: '3 Months', value: 3 }, { name: '6 Months', value: 6 }, { name: '12 Months', value: 12 }];
const trainingTypes = [{ name: 'General Training', value: 1 }, { name: 'Personal Training', value: 2 }, { name: 'Zumba Training', value: 3 }];
const paymentTypes = [{ name: 'Online', value: 1 }, { name: 'Cash', value: 2 }, { name: 'Credit/Debit', value: 3 }];
const empTypes = [{ name: 'Admin', value: 1 }, { name: 'Manager', value: 2 }, { name: 'Front Office', value: 3 }, { name: 'Trainer', value: 4 }, { name: 'Zumba Trainer', value: 5 }, { name: 'Attender', value: 6 }];

const AddClient = () => {
    const { clientId } = useParams();
    const isEditMode = !!clientId;
    const navigate = useNavigate();
    const [branches, setBranches] = useState<GetBranches[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [regDate, setRegDate] = useState(new Date());

    const [initialFormData, setInitialFormData] = useState({
        ClientName: '',
        ClientGender: 0,
        ClientMobileNo: '',
        ClientEmailId: '',
        ClientPackage: 0,
        ClientRegDate: moment().format('YYYY-MM-DD'),
        ClientPayTrainner: 0,
        ClientPayTrainerType: 0,
        ClientPayFee: '',
        ClientPayTFee: '',
        ClientPayBalanceFee: '',
        ClientAddress: '',
        ClientGymBranchId: 0,
        ClientPayPaymentType: 0
    });

    const submitForm = useCallback(
        async (values: ClientFormType) => {
            try {
                if (isEditMode) {
                    // If in edit mode, call the update method
                    const { success, message } = await clientService.updateClient(Number(clientId), values);
                    if (success) {
                        alert(message);
                        navigate('/clients');
                    }
                } else {
                    const custInfo = secureLocalStorage.getItem("custInfo");
                    const customerInfo = {
                        ...custInfo as Customer
                    }
                    const paymentData = {
                        ...values,
                        ClientGymId: customerInfo.CustId
                    };
                    const { success, message } = await clientService.createClient(paymentData);
                    if (success) {
                        alert(message);
                        navigate("/clients");
                    }
                }

            } catch (error) {
                console.log("error == ", error);
            }
        },
        []
    );

    const getBranchesByCustId = async () => {
        try {
            const custInfo = secureLocalStorage.getItem("custInfo");
            const customerInfo = {
                ...custInfo as Customer
            }
            const { data } = await branchService.getBranchesByCustId(customerInfo.CustId);
            if (data.length) {
                setBranches(data);
            }
        } catch (err) {
            console.log('error from getAllBranches ', err);
        } finally {
            //setLoading(false);
        }
    };

    const getEmployeesList = useCallback(async () => {
        try {
            const loginEmpType = secureLocalStorage.getItem("userType");
            const empTypeObj = empTypes.find(res => res.name === loginEmpType);
            if (empTypeObj) {
                const { data } = await employeeService.getGymEmployeesByEmpType(empTypeObj.value);
                if (data) {
                    setEmployees(data);
                }
            }
        } catch (err) {
            console.log('error getCustomerList', err);
        } finally {
            //setLoading(false);
        }
    }, [employees]);

    useEffect(() => {
        getBranchesByCustId();
        getEmployeesList();
        if (isEditMode && clientId) {
            // Fetch branch data for editing
            const fetchClientData = async () => {
                try {
                    const { data } = await clientService.getClientById(Number(clientId));
                    if (data.length) {
                        const clientData = {
                            ...data[0],
                            ClientRegDate: moment(data[0].ClientRegDate).format('YYYY-MM-DD'),
                        }
                        setInitialFormData(clientData);
                    }
                } catch (err) {
                    console.log('error from getClientById ', err);
                }
            };

            fetchClientData();
        }
    }, [isEditMode, clientId]);

    return (
        <>
            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <Link to={"/clients"} className="btn btn-hovers btn-sm"><i className="bi-arrow-left"></i> Back</Link>
                </div>
                <div>

                </div>
            </div>

            {/* <!-- Add Customer Form --> */}
            <div className="col-12 col-lg-9 col-xl-6 m-auto">
                <h1 className="fs-18 fw-700 text-center w-100 mb-3">{isEditMode ? 'Edit Client' : 'Add Client'}</h1>
                <div className="dashboard-card bg-white text-start mb-5">
                    <Formik
                        onSubmit={submitForm}
                        enableReinitialize
                        validationSchema={getValidationSchema}
                        initialValues={initialFormData}
                    >
                        {(formikProps: FormikProps<ClientFormType>) => {
                            const { isValid, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm } = formikProps;

                            return (
                                <><form onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Name <span className="text-danger">*</span></label>
                                            <Field name="ClientName">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Name" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Gender <span className="text-danger">*</span></label>
                                            <Field name="ClientGender">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedGenderOption = genders.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="Gender"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={genders}
                                                                placeholder="Select Gender"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedGenderOption}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Mobile Number <span className="text-danger">*</span></label>
                                            <Field name="ClientMobileNo">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter MobileNo" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Email Address</label>
                                            <Field name="ClientEmailId">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Email" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Package <span className="text-danger">*</span></label>
                                            <Field name="ClientPackage">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedPackageOption = packagesList.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="ClientPackage"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={packagesList}
                                                                placeholder="Select Package"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedPackageOption}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Registration Date</label>
                                            <Field name="ClientRegDate">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            {/* <DatePicker
                                                                format='dd/MM/yyyy'
                                                                onChange={(date: any) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, date);
                                                                    setRegDate(date);
                                                                }}
                                                                clearIcon={null}
                                                                value={regDate}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            /> */}
                                                            <input
                                                                type="date"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''
                                                                    }`}
                                                                maxLength={10}
                                                            />

                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Trainer <span className="text-danger">*</span></label>
                                            <Field name="ClientPayTrainner">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedClientPayTrainnerOption = employees.find((option) => option.EmpId === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="Trainer"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={employees}
                                                                placeholder="Select Trainer"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.EmpId);
                                                                }}
                                                                value={selectedClientPayTrainnerOption}
                                                                getOptionLabel={(option) => option.EmpName}
                                                                getOptionValue={(option) => option.EmpId.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Training Type</label>
                                            <Field name="ClientPayTrainerType">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedClientPayTrainerTypeOption = trainingTypes.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="Training Type"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={trainingTypes}
                                                                placeholder="Select Training Type"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedClientPayTrainerTypeOption}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Fee <span className="text-danger">*</span></label>
                                            <Field name="ClientPayFee">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Fee" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Training Fee</label>
                                            <Field name="ClientPayTFee">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Training Fee" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Balance Amount <span className="text-danger">*</span></label>
                                            <Field name="ClientPayBalanceFee">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Balance Amount" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="">Payment Type</label>
                                            <Field name="ClientPayPaymentType">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    const selectedPaymentTypesOption = paymentTypes.find((option) => option.value === field.value);
                                                    return (
                                                        <>
                                                            <Select
                                                                name="Payment Type"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable
                                                                options={paymentTypes}
                                                                placeholder="Select Training Type"
                                                                onChange={(selectedOption) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, selectedOption?.value);
                                                                }}
                                                                value={selectedPaymentTypesOption}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.value.toString()}
                                                            />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="">Address</label>
                                        <Field name="ClientAddress">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error = getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                return (
                                                    <>
                                                        <textarea
                                                            value={field.value}
                                                            onChange={(ev) => {
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, ev.target.value);
                                                            }}
                                                            className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            placeholder="Enter Address" >
                                                        </textarea>
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );

                                            }}
                                        </Field>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="">Branch</label>
                                        <Field name="ClientGymBranchId">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error =
                                                    getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                const selectedBranchOption = branches.find((option) => option.BranchId === field.value);
                                                return (
                                                    <>
                                                        <Select
                                                            name="ClientGymBranchId"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isSearchable
                                                            options={branches}
                                                            placeholder="Select Branch"
                                                            onChange={(selectedOption) => {
                                                                const { BranchId, BranchName } =
                                                                    selectedOption as GetBranches;
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, BranchId);
                                                            }}
                                                            value={selectedBranchOption}
                                                            getOptionLabel={(option) => option.BranchName}
                                                            getOptionValue={(option) => option.BranchId.toString()}
                                                        />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );
                                            }}
                                        </Field>
                                    </div>
                                    <div className="w-100 text-end">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                    </div>
                                </form>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default AddClient

const getValidationSchema = () =>
    objectYup().shape({
        ClientName: stringYup()
            .required('Name is required.'),
        ClientGender: numberYup()
            .required('Gender is required.'),
        ClientMobileNo: stringYup()
            .required('Mobile number is required.')
            .min(10, 'Mobile No 10 numbers only'),
        ClientPackage: numberYup()
            .required('Package is required.'),
        ClientPayTrainner: stringYup()
            .required('Trainner is required.'),
        ClientPayFee: stringYup()
            .required('Fee is required.'),
        ClientPayBalanceFee: stringYup()
            .required('BalanceFee is required.'),
        ClientGymBranchId: numberYup()
            .required('Branch is required.')
    });
