import React, { useEffect, useState, useRef  } from 'react'
import { SessionStorageManager } from '../../lib/sessionlStorage-manager';
import { PackageForm } from '../../types/package';
import { calculateGST, calculateTotal } from '../../lib/utils/amountCaliculation';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { customerService } from '../../lib/api/customer';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { routes } from '../../routes/routes-names';

const packages = [{packageType : "Silver", value: 1, branchCount: "1", duration : 1, amount: 1000 },
				{packageType : "Bronze",value: 2, branchCount: "2", duration : 3, amount: 1500 },
				{packageType : "Gold",value: 3, branchCount: "5", duration : 6, amount: 2000 },
				{packageType : "Platinum",value: 4, branchCount: "Unlimited", duration : 12, amount: 3799 }];

function PackageDetailsPopup({ closePopup } : any) {
	const navigate = useNavigate();
	const closeButtonRef = useRef<HTMLButtonElement | null>(null);
	const [paymentUrl, setPaymentUrl] = useState('');
	const [selectedPackage, setSelectedPackage] = useState<PackageForm>();

	// Access Redux state using useSelector
	const dataChild1 = useSelector((state: any) => state.children.dataChild1);
	const dataChild2 = useSelector((state: any) => state.children.dataChild2);
	const dataChild3 = useSelector((state: any) => state.children.dataChild3);


	const initiatePayment = async () => {
		try {
			const response = await axios.post('http://localhost:4000/api/oamboopayments/generatePaymentURL', {
			  // Include any required parameters
			  order_id: '12345',
			  currency: 'INR',
			  amount: '1',
			  redirect_url: 'http://localhost:3000/dashboard',
			});
	  
			// Extract the payment URL from the server response
			const { payment_url } = response.data;
			//setPaymentUrl(payment_url);
	  
			// Redirect the user to the payment URL
			//window.location.href = payment_url;
		  } catch (error: any) {
			console.error('Error initiating payment:', error.message);
		  }
	  };

	  const createCustomer = async () => {
		try {
			const reqData = {
				CustGYMName: dataChild2.CustGYMName,
				CustName: dataChild2.CustGYMName,
				CustContactNo: dataChild2.CustContactNo,
				CustEmailId: dataChild2.CustEmailId,
				CustAddress: dataChild2.CustAddress,
				CustPackage: Number(dataChild3),
				CustBranchQouta: Number(dataChild3) == 1 ? 1 : Number(dataChild3) == 2 ? 2 : Number(dataChild3) == 3 ? 5: Number(dataChild3) == 4 ? 100 : 0,
			}
			
			const { success,message } = await customerService.createCustomer(reqData);
			if(success)
			{
				alert(message);
				if (closeButtonRef.current) {
					closeButtonRef.current.click();
				}
				navigate(routes.main);
			}
			
		  } catch (error: any) {
			console.error('Error initiating payment:', error.message);
		  }
	  };


	useEffect(() => {
		 // Do something with dataChild2 and dataChild3
		if(dataChild3)
		{
			const packageObj = packages.find(ele => ele.value === dataChild3);
			setSelectedPackage(packageObj);
		}
	},[selectedPackage, dataChild2, dataChild3]);

	return (
		<>
			<div className="d-flex align-items-center justify-content-between p-3">
				<div>
					<h2 className="fw-600 fs-18 mb-0">{selectedPackage?.packageType} Package Selected</h2>
					<p className="fs-10 mb-0">Please check your selected package price breck-ups details.</p>
				</div>
				<button ref={closeButtonRef} type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" onClick={() => closePopup()} aria-label="Close"></button>
			</div>
			<div className="d-flex px-3 py-1">
				<div className="me-3"><i className="bi-check2 text-success fs-18"></i></div>
				<div className="fs-16 fw-400">{selectedPackage?.branchCount} Branches</div>
			</div>
			<div className="d-flex px-3 py-1">
				<div className="me-3"><i className="bi-check2 text-success fs-18"></i></div>
				<div className="fs-16 fw-400">Full loaded modules</div>
			</div>
			<div className="d-flex px-3 py-1">
				<div className="me-3"><i className="bi-check2 text-success fs-18"></i></div>
				<div className="fs-16 fw-400">Business page</div>
			</div>
			<div className="d-flex px-3 py-1">
				<div className="me-3"><i className="bi-check2 text-success fs-18"></i></div>
				<div className="fs-16 fw-400">{selectedPackage?.duration} Months Duration</div>
			</div>
			<div className="d-flex align-items-center justify-content-between px-3 py-1">
				<div>Sub Total</div>
				<div className="fw-600 fw-14"><i className='bi-currency-rupee'></i> {selectedPackage?.amount}</div>
			</div>
			<div className="d-flex align-items-center justify-content-between px-3 py-1">
				<div>GST 18%</div>
				<div className="fw-600 fw-14"><i className='bi-currency-rupee'></i> {calculateGST(Number(selectedPackage?.amount))}</div>
			</div>
			<div className="d-flex align-items-center justify-content-between px-3 py-1 mb-3">
				<div>Total Amount</div>
				<div className="fw-600 fw-14"><i className='bi-currency-rupee'></i> {calculateTotal(Number(selectedPackage?.amount))}</div>
			</div>
			<div className="px-3">
				{/* <Link to={"/dashboard"} className="btn btn-primary w-100 mb-3">Continue to Payment</Link> */}
				<button type="button" className="btn btn-primary w-100 mb-3" onClick={createCustomer}>Continue to Payment</button>
			</div>
		</>
	)
}

export default PackageDetailsPopup