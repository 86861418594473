import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ErrorMessage, Field, FieldProps, Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import getValue from 'lodash/get';
import Select from 'react-select';
import { BranchFormType } from '../../types/branch';
import { branchService } from '../../lib/api/branch';
import secureLocalStorage from 'react-secure-storage';
import { Customer } from '../../types/customer';

const empTypes = [{ name: 'Admin', value: 6 }, { name: 'Manager', value: 1 }, { name: 'Office Executive', value: 2 }, { name: 'Attender', value: 5 }];

const AddEditBranch = () => {
	const navigate = useNavigate();
	const { branchId } = useParams();
	const currentYear = new Date().getFullYear();
	const isEditMode = !!branchId;

	const years = Array.from({ length: 30 }, (_, index) => ({
		value: (currentYear - index).toString(),
		name: (currentYear - index).toString(),
	}));

	const [initialFormData, setInitialFormData] = useState({
		BranchName: '',
		BranchEmail: '',
		BranchAddress: '',
		BranchContactNo: '',
		BranchAboutUs: '',
		BranchYear: '',
	});

	const submitForm = useCallback(
		async (values: BranchFormType) => {
			try {
				if (isEditMode) {
					// If in edit mode, call the update method
					const { success, message } = await branchService.updateGymBranch(Number(branchId), values);
					if (success) {
						alert(message);
						navigate('/branches');
					}
				} else {
					const custInfo = secureLocalStorage.getItem("custInfo");
					const customerInfo = {
						...custInfo as Customer
					}
					if (customerInfo) {
						const branchData = {
							...values,
							BranchGymId: customerInfo.CustId,
							BranchStatus: 1
						};
						// If in add mode, call the create method
						const { success, message } = await branchService.createGymBranch(branchData);
						if (success) {
							alert(message);
							navigate('/branches');
						}
					}
				}
				// const { success,message } = await branchService.createGymBranch(branchData);
				// if(success)
				// {
				//     alert(message);
				//     navigate("/branches");
				// }
			} catch (error) {
				console.log("error == ", error);
			}
		},
		[]
	);

	useEffect(() => {
		if (isEditMode && branchId) {
			// Fetch branch data for editing
			const fetchBranchData = async () => {
				try {
					const { data } = await branchService.getGymBranchById(Number(branchId));
					if (data.length) {
						setInitialFormData(data[0]);
					}
				} catch (err) {
					console.log('error from getGymBranchById ', err);
				}
			};

			fetchBranchData();
		}
	}, [isEditMode, branchId]);


	return (
		<>
			{/* <!-- Page Title --> */}
			<div className="d-flex align-items-center justify-content-between mb-3">
				<div>
					<Link to={"/branches"} className="btn btn-hovers btn-sm"><i className="bi-arrow-left"></i> Back</Link>
				</div>
				<div>

				</div>
			</div>

			{/* <!-- Add Branch Form --> */}
			<div className="col-12 col-lg-6 m-auto">
				<h1 className="fs-18 fw-700 text-center w-100">{isEditMode ? 'Edit Branch' : 'Add Branch'}</h1>
				<ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
					<li className="nav-item me-3" role="presentation">
						<button className="btn btn-primary w-100 active" id="AboutGYM-tab" data-bs-toggle="pill" data-bs-target="#AboutGYM"
							type="button" role="tab" aria-controls="AboutGYM" aria-selected="true">About GYM</button>
					</li>
					<li className="nav-item me-3" role="presentation">
						<button className="btn btn-primary w-100" id="ContactDetails-tab" data-bs-toggle="pill" data-bs-target="#ContactDetails"
							type="button" role="tab" aria-controls="ContactDetails" aria-selected="false">Contact Details</button>
					</li>
				</ul>
				<Formik
					onSubmit={submitForm}
					enableReinitialize
					validationSchema={getValidationSchema}
					initialValues={initialFormData}
				>
					{(formikProps: FormikProps<BranchFormType>) => {
						const { isValid, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm } = formikProps;

						return (
							<><form onSubmit={handleSubmit}>
								<div className="tab-content" id="pills-tabContent">
									<div className="tab-pane fade show active" id="AboutGYM" role="tabpanel" aria-labelledby="AboutGYM-tab" tabIndex={0}>
										<div className="dashboard-card bg-white text-start mb-5">
											<div className="mb-3">
												<label htmlFor="" className="fw-600 mb-2">About GYM</label>
												<Field name="BranchAboutUs">
													{(fieldProps: FieldProps) => {
														const { field, form } = fieldProps;
														const error = getValue(form.touched, field.name) &&
															getValue(form.errors, field.name);
														return (
															<>
																<textarea
																	value={field.value}
																	onChange={(ev) => {
																		setFieldTouched(field.name);
																		setFieldValue(field.name, ev.target.value);
																	}}
																	className={`form-control about-gym ${error ? 'is-invalid' : ''}`}
																>
																</textarea>
																{error && <small className="text-danger">{error.toString()}</small>}
															</>
														);

													}}
												</Field>
											</div>
											{/* <label htmlFor="" className="fs-14 fw-600 mb-2">Week Days and Timings</label>
											<div className="d-flex mb-3">
												<div className="col pe-3">
													<select name="" id="" className="form-select mb-3">
														<option value="">Select</option>
														<option value="">Monday - Saturday</option>
														<option value="">Sunday</option>
													</select>
												</div>
												<div className="col ps-3">
													<select name="" id="" className="form-select mb-3">
														<option value="">Select</option>
														<option value="">Sunday</option>
														<option value="">Monday</option>
													</select>
												</div>
											</div> */}
											<div className="w-100 text-end">
												<button type="button" className="btn btn-primary">Next</button>
											</div>
										</div>
									</div>
									<div className="tab-pane fade" id="ContactDetails" role="tabpanel" aria-labelledby="ContactDetails-tab" tabIndex={0}>
										<div className="dashboard-card bg-white text-start mb-5">
											<div className="row">
												<div className="col-12 col-lg-7 mb-3 mb-lg-0">
													<label htmlFor="">Branch Name</label>
													<Field name="BranchName">
														{(fieldProps: FieldProps) => {
															const { field, form } = fieldProps;
															const error = getValue(form.touched, field.name) &&
																getValue(form.errors, field.name);
															return (
																<>
																	<input
																		type="text"
																		value={field.value}
																		onChange={(ev) => {
																			setFieldTouched(field.name);
																			setFieldValue(field.name, ev.target.value);
																		}}
																		className={`form-control ${error ? 'is-invalid' : ''}`}
																		placeholder="Enter Name" />
																	{error && <small className="text-danger">{error.toString()}</small>}
																</>
															);

														}}
													</Field>
												</div>
												<div className="col-12 col-lg-5 mb-3 mb-lg-0">
													<label htmlFor="">Year</label>
													<Field name="BranchYear">
														{(fieldProps: FieldProps) => {
															const { field, form } = fieldProps;
															const error =
																getValue(form.touched, field.name) &&
																getValue(form.errors, field.name);
															const selectedYearOption = years.find((option) => option.value === field.value);
															return (
																<>
																	<Select
																		name="BranchYear"
																		className="react-select"
																		classNamePrefix="react-select"
																		isSearchable
																		options={years}
																		placeholder="Select Year"
																		value={selectedYearOption}
																		onChange={(selectedOption) => {
																			setFieldTouched(field.name);
																			setFieldValue(field.name, selectedOption?.value);
																		}}
																		getOptionLabel={(option) => option.name}
																		getOptionValue={(option) => option.value.toString()}
																	/>
																	{error && <small className="text-danger">{error.toString()}</small>}
																</>
															);
														}}
													</Field>
												</div>
											</div>
											<div className="mb-3">
												<label htmlFor="">Address</label>
												<Field name="BranchAddress">
													{(fieldProps: FieldProps) => {
														const { field, form } = fieldProps;
														const error = getValue(form.touched, field.name) &&
															getValue(form.errors, field.name);
														return (
															<>
																<textarea
																	value={field.value}
																	onChange={(ev) => {
																		setFieldTouched(field.name);
																		setFieldValue(field.name, ev.target.value);
																	}}
																	className={`form-control ${error ? 'is-invalid' : ''}`}
																	placeholder="Enter Address" >
																</textarea>
																{error && <small className="text-danger">{error.toString()}</small>}
															</>
														);

													}}
												</Field>
											</div>
											<div className="mb-3">
												<label htmlFor="">Contact Number</label>
												<Field name="BranchContactNo">
													{(fieldProps: FieldProps) => {
														const { field, form } = fieldProps;
														const error = getValue(form.touched, field.name) &&
															getValue(form.errors, field.name);
														return (
															<>
																<input
																	type="text"
																	value={field.value}
																	onChange={(ev) => {
																		setFieldTouched(field.name);
																		setFieldValue(field.name, ev.target.value);
																	}}
																	className={`form-control ${error ? 'is-invalid' : ''}`}
																	placeholder="Enter Name" />
																{error && <small className="text-danger">{error.toString()}</small>}
															</>
														);

													}}
												</Field>
											</div>
											<div className="mb-3">
												<label htmlFor="">Email</label>
												<Field name="BranchEmail">
													{(fieldProps: FieldProps) => {
														const { field, form } = fieldProps;
														const error = getValue(form.touched, field.name) &&
															getValue(form.errors, field.name);
														return (
															<>
																<input
																	type="text"
																	value={field.value}
																	onChange={(ev) => {
																		setFieldTouched(field.name);
																		setFieldValue(field.name, ev.target.value);
																	}}
																	className={`form-control ${error ? 'is-invalid' : ''}`}
																	placeholder="Enter Email" />
																{error && <small className="text-danger">{error.toString()}</small>}
															</>
														);

													}}
												</Field>
											</div>
											<div className="w-100 text-end">
												<button type="submit" className="btn btn-primary" disabled={isSubmitting}>Save & Submit</button>
											</div>
										</div>
									</div>
								</div>
							</form>
							</>
						);
					}}
				</Formik>
			</div>
		</>
	)
}

export default AddEditBranch

const getValidationSchema = () =>
	objectYup().shape({
		BranchName: stringYup()
			.required('Branch name is required.'),
		BranchAddress: stringYup()
			.required('Address is required.'),
		BranchContactNo: numberYup()
			.required('Contact number is required.')
			.min(10, 'Contact No 10 numbers only'),
		BranchEmail: stringYup()
			.email('Enter valid email')
			.required('Email is required.'),
	});
