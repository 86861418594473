export const SessionStorageManager = {
    getBusinessType: function () {
        const businessType = sessionStorage.getItem('businessType');
        return businessType || null;
    },

    setBusinessType: function (businessType: string) {
        sessionStorage.setItem('businessType', businessType);
    },

    removeBusinessType: function () {
        sessionStorage.removeItem('businessType');
    },

    //Customer
    getCustId: function () {
        const custId = sessionStorage.getItem('custId');
        return custId || null;
    },

    setCustId: function (custId: string) {
        sessionStorage.setItem('custId', custId);
    },

    removeCustId: function () {
        sessionStorage.removeItem('custId');
    },

    //Customer
    getCustInfo: function () {
        const custInfo = sessionStorage.getItem('custInfo');
        return custInfo || null;
    },

    setCustInfo: function (custInfo: any) {
        sessionStorage.setItem('custInfo', custInfo);
    },

    removeCustInfo: function () {
        sessionStorage.removeItem('custInfo');
    },
};
