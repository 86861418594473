  export const UPDATE_DATA_CHILD1 = 'UPDATE_DATA_CHILD1';
  export const UPDATE_DATA_CHILD2 = 'UPDATE_DATA_CHILD2';
  export const UPDATE_DATA_CHILD3 = 'UPDATE_DATA_CHILD3';
  export const LOGIN_USER_ACTION = 'LOGIN_USER_ACTION';
  export const RESET_STORE = 'RESET_STORE';

  export const updateDataChild1 = (data) => ({
    type: UPDATE_DATA_CHILD1,
    payload: data,
  });
  
  export const updateDataChild2 = (data) => ({
    type: UPDATE_DATA_CHILD2,
    payload: data,
  });
  
  export const loginUserAction = (data) => ({
    type: LOGIN_USER_ACTION,
    payload: data,
  });

  export const updateDataChild3 = (data) => ({
    type: UPDATE_DATA_CHILD3,
    payload: data,
  });
  
  export const resetStore = () => ({
    type: RESET_STORE,
  });