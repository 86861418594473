import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { customerService } from '../../lib/api/customer';
import { Customer } from '../../types/customer';
import Table from "../../components/Table";
import { GetBranches } from '../../types/branch';
import { branchService } from '../../lib/api/branch';
import { clientService } from '../../lib/api/client';
import secureLocalStorage from 'react-secure-storage';

const ClientList = () => {
    const fetchIdRef = useRef(0);
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [branches, setBranches] = useState<GetBranches[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [branchName, setBranchName] = useState(null);
    const [clientName, setClientName] = useState<string | null>(null);
    const [clientMobileNo, setClientMobileNo] = useState<string | null>(null);
    const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
    const [statusValue, setStatusValue] = useState(null);
    const [statusData, setStatusData] = useState([
        { id: 1, name: 'Pending', value: 1, isChecked: false },
        { id: 2, name: 'Active', value: 2, isChecked: false },
        { id: 3, name: 'Renewals', value: 3, isChecked: false },
        { id: 4, name: 'Archive', value: 4, isChecked: false }
    ]);

    const getCustomerList = useCallback(async () => {
        try {
            const { data } = await customerService.getCustomerList();
            if (data) {
                setCustomers(data);
            }

        } catch (err) {
            console.log('error getCustomerList', err);
        } finally {
            //setLoading(false);
        }
    }, [customers]);

    const fetchData = useCallback(async ({ pageSize, pageIndex }: any) => {

        const fetchId = ++fetchIdRef.current

        const { success, data } = await clientService.getGymClientsByFilter(selectedBranchId, statusValue, clientName, clientMobileNo);
        if (success) {
            setLoading(true);

            setTimeout(() => {
                // Only update the data if this is the latest fetch
                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex
                    const endRow = startRow + pageSize
                    setCustomers(data.slice(startRow, endRow))

                    // Your server could send back total page count.
                    // For now we'll just fake it, too
                    setPageCount(Math.ceil(data.length / pageSize));

                    setLoading(false)
                }
            }, 1000);
        }
    }, [selectedBranchId, statusValue, clientName, clientMobileNo]);

    const columns = [
        {
            Header: "Name",
            accessor: "ClientName"
        },
        {
            Header: "Email Address",
            accessor: "ClientEmailId"
        },
        {
            Header: "Branch",
            accessor: "BranchName"
        },
        {
            Header: "Contact No",
            accessor: "ClientMobileNo"
        },
        {
            Header: "Registration Date",
            accessor: "ClientRegDate",

        },
        {
            Header: "Address",
            accessor: "ClientAddress",
        },
        {
            Header: "Status",
            accessor: "ClientStatus",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <>
                        {value === 2 && <span className="alert alert-success rounded-pill py-0 px-2 fs-12">Approved</span>}
                        {value === 1 && <span className="alert alert-warning rounded-pill py-0 px-2 fs-12">Pending</span>}
                    </>
                );
            }
        },
        {
            Header: "Action",
            Cell: (cell: any) => (
                <>
                    <button type="button" className="btn btn-hovers" title="Edit"><i className="bi-pencil"
                        onClick={() => {
                            navigate(`/add-edit-client/${Number(cell.row.original.ClientId)}`)
                        }

                        }></i></button>
                    <button type="button" className="btn btn-hovers" title="Delete"><i className="bi-trash3"></i></button>
                </>
            )
        }
    ];

    const getBranchesByCustId = async () => {
        try {
            const custInfo = secureLocalStorage.getItem("custInfo");
            const customerInfo = {
                ...custInfo as Customer
            }
            const { data } = await branchService.getBranchesByCustId(Number(customerInfo.CustId));
            if (data.length) {
                setBranches(data);
            }
        } catch (err) {
            console.log('error from getAllBranches ', err);
        } finally {
            //setLoading(false);
        }
    };

    const handleChecked = (e: any) => {
        setStatusValue(e.target.value);
        const res = statusData.map((d) => {
            if (d.value.toString() === e.target.value.toString()) {
                return { ...d, isChecked: !d.isChecked };
            }
            else {
                return { ...d, isChecked: false };
            }
        });

        const eamtyArray = res.filter((d) => {
            return d.isChecked === true
        });
        if (eamtyArray.length === 0) {
            setStatusValue(null);
        }
        setStatusData(res);
        //fetchData({});
    };

    const handleBranchChange = useCallback((selectedBranch: string) => {
        setSelectedBranchId((prevSelectedBranchId) => {
            // Toggle the selected branch
            if (prevSelectedBranchId === selectedBranch) {
                // Unselect the branch if it was already selected
                return null;
            } else {
                // Select the branch if it was not selected
                return selectedBranch;
            }
        });
    }, [setSelectedBranchId]);
    useEffect(() => {
        getBranchesByCustId();
    }, []);
    return (
        <>
            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="fw-700 fs-18 secondary-text">Clients</h1>
                </div>
                <div>
                    <Link to={'/add-edit-client'} className="btn btn-hovers btn-sm"><i className="bi-plus-lg"></i> Add Client</Link>
                </div>
            </div>

            {/* <!-- Page Data Filter --> */}
            <div className="input-group input-group-lg page-data-filter shadow-sm">
                <span className="input-group-text pe-0 ps-2 border-0"><i className="bi-funnel"></i></span>
                <input type="text" className="form-control fs-14 border-0"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value != null && value != "") {
                            setClientName(value);
                            setClientMobileNo(value);
                        }
                        else {
                            setClientName(null);
                            setClientMobileNo(null);
                        }

                    }}
                    placeholder="Search with Client name or Mobile number.." />
                <button className="btn btn-outline-secondary dropdown-toggle border-0 fs-14" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Status</button>
                <ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0">

                    {statusData.map((d: any) => (
                        <div className="p-2">
                            <label htmlFor={`branch-${d.id}`}>
                                <input
                                    id={`branch-${d.id}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={d.isChecked}
                                    name="branchGroup"
                                    value={d.value}
                                    onChange={handleChecked}
                                />
                                <span className="form-check-label ms-2 fw-600">{d.name}</span>
                            </label>
                        </div>
                    ))}
                </ul>
                <button className="btn btn-outline-secondary dropdown-toggle border-0 fs-14" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Branch</button>
                <ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0">
                    {branches.map((branch) => (
                        <div className="p-2" key={branch.BranchId}>
                            <label htmlFor={`checkbox-${branch.BranchId}`}>
                                <input
                                    id={`checkbox-${branch.BranchId}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedBranchId === branch.BranchName}
                                    name="checkboxGroup"
                                    onChange={() => handleBranchChange(branch.BranchName)}
                                />
                                <span className="form-check-label ms-2 fw-600">{branch.BranchName}</span>
                            </label>
                        </div>
                    ))}
                </ul>
                <button type="button" className="btn btn-primary search-btn border-0"><i className="bi-search"></i></button>
            </div>
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <Table
                        columns={columns}
                        data={customers}
                        loading={loading}
                        pageCount={pageCount}
                        fetchData={fetchData}
                    />
                </div>
            </div>
            {/* <!-- List Item Starts --> */}
            {/* <Link to={"/view-customer"} className="card box-shadow-sm mb-3 div-list-items">
                <div className="card-body p-1 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                            <img src="../images/avthar-xs.png" alt="" />
                        </div>
                        <div>
                            <h2 className="fw-600 fs-14 mb-1">Konda Vamshi Das</h2>
                            <p className="mb-0 fs-12"><i className="bi-geo-alt"></i> Nagole, Hyderabad - 500068</p>
                        </div>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Branch</h2>
                        <p className="mb-0 fs-14">Fitness Studio</p>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Contact Number</h2>
                        <p className="mb-0 fs-14">+91 9381432416</p>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Date of Joining</h2>
                        <p className="mb-0 fs-14">12th Jan 2022</p>
                    </div>
                    <div>
                        <h2 className="fw-600 fs-12 mb-1">Status</h2>
                        <span className="status-success-bg rounded-pill px-2 py-0 fs-12">Active</span>
                    </div>
                    <div className="d-flex px-2">
                        <button type="button" className="btn btn-hovers" title="Edit"><i className="bi-pencil"></i></button>
                        <button type="button" className="btn btn-hovers" title="Delete"><i className="bi-trash3"></i></button>
                    </div>
                </div>
            </Link> */}
            {/* <!-- List Item End --> */}
        </>
    )
}

export default ClientList
