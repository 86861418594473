import React from 'react'
import { Link } from 'react-router-dom'

function Nutritionist() {
	return (
		<>
			<section className='product-banner'>
				<div className="container">
					<div className="hero-title text-center">
						<h1 className="fs-28 fw-700"><span>Find Your</span> Nutrition Consultant</h1>
						<p className="fs-16">Unlock Your Best Self, Discover the Perfect Nutrition Consultant for Your Wellness Journey!</p>
						<div className="input-group input-group-lg page-data-filter">
							<span className="input-group-text pe-0 ps-2 bg-white"><i className="bi-geo-alt fs-14"></i></span>
							<button className="btn dropdown-toggle border-end-0 fs-14 home-filter-drop d-flex align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Select Location</button>
							<ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0 dropstart">
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Nutrition" className="w-100 mb-0 fw-400">Hyderabad</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Suppliement" className="w-100 mb-0 fw-400">Secendrabad</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Fitnewss" className="w-100 mb-0 fw-400">Telangana</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="ServiceVender" className="w-100 mb-0 fw-400">Andhra Pradesh</label>
								</li>
							</ul>
							<button className="btn dropdown-toggle border-end-0 fs-14 home-filter-drop d-flex align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Nutritionist Type</button>
							<ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0 dropstart">
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Nutrition" className="w-100 mb-0 fw-400">Clinical Nutritionist</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Suppliement" className="w-100 mb-0 fw-400">Gerontological Nutritionist</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="Fitnewss" className="w-100 mb-0 fw-400">Public Health Nutritionist</label>
								</li>
								<li className="d-flex align-items-center px-2 py-2">
									<label htmlFor="ServiceVender" className="w-100 mb-0 fw-400">Management Nutritionist</label>
								</li>
							</ul>
							<input type="text" className="form-control fs-14 border-start border-secondary" placeholder="Search with nutritionist name or clinic name..." />
							<button type="button" className="btn btn-primary search-btn"><i className="bi-search"></i></button>
						</div>
						<div className="m-auto col-9 mt-5">
							<p className='fs-16'>Join our thriving community of wellness professionals! Register your clinic or nutrition consultancy on our website and connect with clients seeking your expertise. Elevate your practice and expand your reach with us!</p>
							<button type='button' className='btn btn-primary rounded-pill'>Register Now</button>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Clinical Nutritionist</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Gerontological Nutritionist</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Public Health Nutritionist</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Management Nutritionist</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Sports Nutritionist</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Holistic Nutritionist</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Health coaching</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Nutrition Consultant</h2>
							</div>
						</div>
						<div className="col-lg-3 col-12 py-0 py-lg-3">
							<div className="nutrit-links">
								<img src={require('./../assets/images/dietary.png')} alt="" />
								<h2 className="fs-14 fw-600 mb-0">Registered Dietitian Nutritionist</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5 bg-white">
				<div className="container my-lg-5">
					<div className='row'>
						<div className="w-100 text-center mb-lg-4">
							<h1 className="fw-700 fs-22">Top Rated Nutrition Consultant</h1>
						</div>
						<div className="col-6 col-lg mb-lg-3 mb-3">
							<div className="card border-0 shadow-sm doct-img">
								<div className="card-body text-center p-0 pb-2 p-lg-3 pb-lg-3">
									<img src={require('../assets/images/dr_photo1.jpg')} alt="" className="mb-3 img-fluid" />
									<h2 className="fw-600 fs-16">Dr. Konda Vamshi Das</h2>
									<div className="d-flex align-items-center justify-content-center my-2">
										<div className='me-2'>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
										</div>
										<div className='fw-600'>4.5</div>
									</div>
									<button type='button' className='btn btn-primary text-nowrap'>Book Appointment</button>
								</div>
							</div>
						</div>
						<div className="col-6 col-lg mb-lg-3 mb-3">
							<div className="card border-0 shadow-sm doct-img">
								<div className="card-body text-center p-0 pb-2 p-lg-3 pb-lg-3">
									<img src={require('../assets/images/dr_photo2.jpg')} alt="" className="mb-3 img-fluid" />
									<h2 className="fw-600 fs-16">Dr. Konda Vamshi Das</h2>
									<div className="d-flex align-items-center justify-content-center my-2">
										<div>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
										</div>
										<div className='fw-600'>4.5</div>
									</div>
									<button type='button' className='btn btn-primary text-nowrap'>Book Appointment</button>
								</div>
							</div>
						</div>
						<div className="col-6 col-lg mb-lg-3 mb-3">
							<div className="card border-0 shadow-sm doct-img">
								<div className="card-body text-center p-0 pb-2 p-lg-3 pb-lg-3">
									<img src={require('../assets/images/dr_photo3.jpg')} alt="" className="mb-3 img-fluid" />
									<h2 className="fw-600 fs-16">Dr. Konda Vamshi Das</h2>
									<div className="d-flex align-items-center justify-content-center my-2">
										<div>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
										</div>
										<div className='fw-600'>4.5</div>
									</div>
									<button type='button' className='btn btn-primary text-nowrap'>Book Appointment</button>
								</div>
							</div>
						</div>
						<div className="col-6 col-lg mb-lg-3 mb-3">
							<div className="card border-0 shadow-sm doct-img">
								<div className="card-body text-center p-0 pb-2 p-lg-3 pb-lg-3">
									<img src={require('../assets/images/dr_photo4.jpg')} alt="" className="mb-3 img-fluid" />
									<h2 className="fw-600 fs-16">Dr. Konda Vamshi Das</h2>
									<div className="d-flex align-items-center justify-content-center my-2">
										<div>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
										</div>
										<div className='fw-600'>4.5</div>
									</div>
									<button type='button' className='btn btn-primary text-nowrap'>Book Appointment</button>
								</div>
							</div>
						</div>
						<div className="col-6 col-lg mb-lg-3 mb-3">
							<div className="card border-0 shadow-sm doct-img">
								<div className="card-body text-center p-0 pb-2 p-lg-3 pb-lg-3">
									<img src={require('../assets/images/dr_photo5.jpg')} alt="" className="mb-3 img-fluid" />
									<h2 className="fw-600 fs-16">Dr. Konda Vamshi Das</h2>
									<div className="d-flex align-items-center justify-content-center my-2">
										<div>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-fill fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
											<i className="bi-star-half fs-18 text-warning me-1"></i>
										</div>
										<div className='fw-600'>4.5</div>
									</div>
									<button type='button' className='btn btn-primary text-nowrap'>Book Appointment</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-100 text-center">
					<Link to="/nutrition-search-results" className="btn btn-outline-primary">See More <i className="bi-arrow-right"></i></Link>
				</div>
			</section>
		</>
	)
}

export default Nutritionist
