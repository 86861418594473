import React from 'react';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { routes } from '../../routes/routes-names';
import { tokenManager } from '../../lib/token-manager';

export interface AllowedRoutes {
    [role: string]: string[]; 
}

export interface RouteDetails {
    [key: string]: { label: string; iconClass: string };
}

export const routeDetails: RouteDetails = {
    "/dashboard": { label: "Dashboard", iconClass: "bi-columns-gap fs-22" },
    "/employees": { label: "Employees", iconClass: "bi-briefcase fs-22" },
    "/clients": { label: "Clients", iconClass: "bi-people fs-22" },
    "/qr-scanner": { label: "QR Scanner", iconClass: "bi-qr-code-scan fs-22" },
    "/accounts": { label: "Accounts", iconClass: "bi-currency-rupee fs-22" },
    "/branches": { label: "Branches", iconClass: "bi-buildings fs-22" },
    "/settings": { label: "Settings", iconClass: "bi-gear fs-22" },
};

export default function LeftNav({ userRole }: { userRole: string }) {
    const signOut = () => {
        secureLocalStorage.removeItem("custInfo");
        secureLocalStorage.removeItem("userType");
        tokenManager.removeToken();
    }

    const allowedRoutes: AllowedRoutes = {
        Admin: [
            routes.dashboard,
            routes.employees,
            routes.clients,
            routes.qrScanner,
            routes.accounts,
            routes.branches,
            routes.settings
        ],
        Manager: [
            routes.dashboard,
            routes.employees,
            routes.clients,
            routes.qrScanner
        ],
        "Front Office": [
            routes.clients,
            routes.qrScanner
        ],
        Trainer: [
            routes.clients
        ],
        "Zumba Trainer": [
            routes.clients
        ]
    };

    const renderNavigationItems = () => {
        const allowedNavItems = allowedRoutes[userRole];
        if (!allowedNavItems) return null;
    
        return allowedNavItems.map((route, index) => {
            const routeDetail = routeDetails[route];
            if (!routeDetail) {
                console.error(`Route detail not found for route: ${route}`);
                return null; // Skip rendering for this route
            }
    
            return (
                <li key={index} className="nav-item">
                    <Link className="nav-link fw-600" to={route}>
                        <i className={routeDetail.iconClass}></i>
                        <span>{routeDetail.label}</span>
                    </Link>
                </li>
            );
        });
    };    

    return (
        <>
            <aside className="px-2 left-navbar">
                <nav className="navbar navbar-expand-lg my-0 d-block">
                    <Link className="navbar-brand me-0" to={"."}>
                        <img src={require('./../../assets/images/colorlogos.png')} alt="" className='img-fluid' />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#LeftSideNav"
                        aria-controls="LeftSideNav" aria-label="Toggle navigation">
                        <i className="bi-list fs-24"></i>
                    </button>
                    <div className="offcanvas offcanvas-start mt-0 mt-lg-2" id="LeftSideNav" aria-labelledby="LeftSideNavLabel">
                        <div className="offcanvas-header align-items-center justify-content-center">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body d-block px-0">
                            <ul className="navbar-nav sidenav-li">
                                {renderNavigationItems()}
                                <li className="nav-item">
                                    <Link className="nav-link fw-600" to={"."} onClick={signOut}>
                                        <i className="bi-box-arrow-right fs-22"></i>
                                        <span>Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </aside>
        </>
    );
}