
export enum PackageEnum {
    Silver = 1,
    Bronze = 2,
    Gold = 3,
    Platinum = 4,
  }

  export const PackageLabels: Record<PackageEnum, string> = {
    [PackageEnum.Silver]: 'Silver',
    [PackageEnum.Bronze]: 'Bronze',
    [PackageEnum.Gold]: 'Gold',
    [PackageEnum.Platinum]: 'Platinum',
  };

export type PackageForm = {
    packageType: string;
    value: number;
    branchCount: string;
    duration: number;
    amount:number;
};