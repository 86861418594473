import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './scroll-to-top';
//import HomePage from '../containers/home';
import { routes } from './routes-names';
//import LeftNav from '../gymEntranceManagement/admin-includes/left-nav';
import Room from '../gymEntranceManagement';
import Dashboard from '../gymEntranceManagement/dashboard';
import ListEmployees from '../gymEntranceManagement/employees/list-employees';
import QRScanner from '../gymEntranceManagement/qr-scanner';
import PaymentList from '../gymEntranceManagement/accounts/payment-list';
import BranchesList from '../gymEntranceManagement/branches/branches-list';
import Services from '../gymEntranceManagement/settings/services';
import AddEditBranch from '../gymEntranceManagement/branches/add-edit-branch';
// import EditBranch from '../gymEntranceManagement/branches/edit-branch';
import ViewEmployee from '../gymEntranceManagement/employees/view-employee';
import AddEditEmployee from '../gymEntranceManagement/employees/add-edit-employee';
import EditEmployee from '../gymEntranceManagement/employees/edit-employee';
import Packages from '../gymEntranceManagement/settings/packages';
import AddEditPayment from '../gymEntranceManagement/accounts/add-edit-payment';
import EditPayment from '../gymEntranceManagement/accounts/edit-payment';
import LandingPage from '../landingPages/home';
import GymProfilePage from '../landingPages/gym-profile-page';
import ClientList from '../gymEntranceManagement/clients/clients-list';
import AddEditClient from '../gymEntranceManagement/clients/add-edit-client';
import EditClient from '../gymEntranceManagement/clients/edit-client';
import ClientView from '../gymEntranceManagement/clients/view-client';
import MainHomePage from '../landingPages/mainhomepage';
import ServiceVendors from '../landingPages/service-vendors';
import Support from '../landingPages/support';
import GymManageSoftware from '../landingPages/gym-manage-software';
import FitnessEquipments from '../landingPages/fitness-equipments';
import Nutritionist from '../landingPages/nutritionist';
import PrivacyPolicy from '../landingPages/privacy-policy';
import CookiesPolicy from '../landingPages/cookies-policy';
import TermsAndConditions from '../landingPages/terms-and-conditions';
import AboutUs from '../landingPages/about-us';
import NutritionSearchResults from '../landingPages/nutrition-search-results';
import ConsultantPage from '../landingPages/consultant-page';

const MainRoute = () => {
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route path={routes.main} element={<MainHomePage />}>
					<Route path={routes.home} element={<LandingPage />} />
					<Route path={routes.gymmanagesoftware} element={<GymManageSoftware />} />
					<Route path={routes.fitnessequipments} element={<FitnessEquipments />} />
					<Route path={routes.nutritionconsultant} element={<Nutritionist />} />
					<Route path={routes.servicevendors} element={<ServiceVendors />} />
					<Route path={routes.support} element={<Support />} />
					<Route path={routes.privacypolicy} element={<PrivacyPolicy />} />
					<Route path={routes.cookiespolicy} element={<CookiesPolicy />} />
					<Route path={routes.termsandconditions} element={<TermsAndConditions />} />
					<Route path={routes.profilepage} element={<GymProfilePage />} />
					<Route path={routes.aboutus} element={<AboutUs />} />
					<Route path={routes.nutritionsearchresults} element={<NutritionSearchResults />} />
					<Route path={routes.consultantpage} element={<ConsultantPage />} />
				</Route>
				
				<Route path={routes.main} element={<Room />}>
					<Route path={routes.dashboard} element={<Dashboard />} />

					{/* Employees */}
					<Route path="/employees" element={<ListEmployees />} />
					<Route path="/add-edit-employee/:empId?" element={<AddEditEmployee />} />
					<Route path="/edit-employee" element={<EditEmployee />} />
					<Route path="/view-employee" element={<ViewEmployee />} />

					{/* Customers */}
					<Route path="/clients" element={<ClientList />} />
					<Route path="/add-edit-client/:clientId?" element={<AddEditClient />} />
					<Route path="/edit-client" element={<EditClient />} />
					<Route path="/view-client" element={<ClientView />} />

					<Route path="/qr-scanner" element={<QRScanner />} />
					{/* Accounts */}
					<Route path="/accounts" element={<PaymentList />} />
					<Route path="/add-edit-payment/:accountId?" element={<AddEditPayment />} />
					<Route path="/accounts/edit-payment" element={<EditPayment />} />

					{/* Branchs */}
					<Route path="/branches" element={<BranchesList />} />
					<Route path="/add-edit-branch/:branchId?" element={<AddEditBranch />} />
					{/* <Route path="/edit-branche" element={<EditBranch />} /> */}

					{/* Settings */}
					<Route path="/settings" element={<Services />} />
					<Route path="/packages" element={<Packages />} />
					<Route path="/services" element={<Services />} />
				</Route>
				
			</Routes>
		</Router>
	);
};

export default MainRoute;
