import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { SessionStorageManager } from '../../lib/sessionlStorage-manager';
import GmsRegForm from './gms-reg-form';
import { connect } from 'react-redux';
import { updateDataChild1 } from '../../store/actions/authActions';

function RegisterPage({ showComponent, closePopup,dataChild1, updateDataChild1 } : any) {

    const [businessType, setBusinessType] = useState(dataChild1 == null?'GYMS':dataChild1);

    const handleOptionChange = useCallback((e : any) => {
        setBusinessType(e.target.value);
        SessionStorageManager.setBusinessType(e.target.value);
      },[]);
      const handleSubmit = () => {
        showComponent('gmsRegForm');
        updateDataChild1(businessType);
      };
    
  return (
    <>
        <div className="d-flex align-items-center justify-content-between p-3">
            <div>
                <h2 className="fw-600 fs-18 mb-0">Get Started</h2>
                <p className="fs-10 mb-0">Select your business type.</p>
            </div>
            <button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" onClick={() => closePopup()} aria-label="Close"></button>
        </div>
        <div className="text-center m-auto px-3">
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-window-sidebar"></i>
                </div>
                <label htmlFor="GYMS" className="form-control label-box">GYM Management System</label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" value="GYMS" onChange={handleOptionChange}
                    checked={businessType === "GYMS"} id="GYMS" name="business_type" />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-person"></i>
                </div>
                <label htmlFor="Nutrit" className="form-control label-box">Nutrition Consultant</label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" value="Nutrit" onChange={handleOptionChange}
                    checked={businessType === "Nutrit"} id="Nutrit" name="business_type" />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-truck"></i>
                </div>
                <label htmlFor="SuppSeller" className="form-control label-box">Supplements Seller</label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" value="SuppSeller" onChange={handleOptionChange}
                    checked={businessType === "SuppSeller"} id="SuppSeller" name="business_type" />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-tags"></i>
                </div>
                <label htmlFor="FitnEquip" className="form-control label-box">Fitness Equipment Seller</label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" value="FitnEquip" onChange={handleOptionChange}
                    checked={businessType === "FitnEquip"} id="FitnEquip" name="business_type" />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-person-gear"></i>
                </div>
                <label htmlFor="ServVend" className="form-control label-box">Service Vendor</label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" value="ServVend" onChange={handleOptionChange}
                    checked={businessType === "ServVend"} id="ServVend" name="business_type" />
                </div>
            </div>
            <button type="button" className="btn btn-primary w-100 mb-3" onClick={() => handleSubmit()}>Continue</button>  {/* need oopen <GmsRegForm /> with in this popup */}
        </div>
    </>
  )
}
const mapStateToProps = (state:any) => ({
    dataChild1: state.children.dataChild1,
  });
  
  export default connect(mapStateToProps, { updateDataChild1 })(RegisterPage);