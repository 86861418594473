import React from 'react';

const Support = () => {
	return (
		<>
			<div className="d-flex">
				<div className='col d-lg-inline-block d-none'>
					<div className="support-banner vh-100 d-flex align-items-center justify-content-center">
						<img src={require('./../assets/images/contact-us.png')} alt="" />
					</div>
				</div>
				<div className='col'>
					<div className='vh-100 d-flex align-items-center justify-content-center'>
						<div>
							<div className='col-lg-10 m-auto card card-body border-0 shadow'>
								<h3 className="fw-700">Enquiry</h3>
								<p className='mb-0'>If you have any application block ages, feedback or suggestions please feel free to send enquiry us.</p>
								<hr className='my-4'/>
								<div className="d-flex mb-3">
									<div className="col">
										<p><i className="bi-telephone primary-text"></i> <strong className='primary-text'>Contact Number</strong> <br /> +91 9381432416</p>
									</div>
									<div className="col">
										<p><i className="bi-envelope primary-text"></i> <strong className='primary-text'>Email Address</strong> <br /> support@gymentrance.com</p>
									</div>
								</div>
								<div className="row align-items-stretch">
									<div className="col-12 col-lg">
										<div className="form-floating mb-3">
											<input type="text" className="form-control" id="FullName" placeholder="Enter" />
											<label htmlFor="FullName"><i className='bi-person primary-text'></i> Fullname</label>
										</div>
									</div>
									<div className="col-12 col-lg">
										<div className="form-floating mb-3">
											<input type="text" className="form-control" id="MobileNumber" placeholder="Enter" />
											<label htmlFor="MobileNumber"><i className='bi-phone primary-text'></i> Mobile Number</label>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-12">
										<div className="form-floating mb-3">
											<input type="text" className="form-control" id="EmailAddress" placeholder="Enter" />
											<label htmlFor="EmailAddress"><i className='bi-envelope primary-text'></i> Email address</label>
										</div>
									</div>
									<div className="col-12">
										<div className="form-floating">
											<textarea className="form-control comments-box" placeholder="Leave a comment here" id="Comments"></textarea>
											<label htmlFor="Comments"><i className="bi-chat-left-text primary-text"></i> Comments</label>
										</div>
									</div>
								</div>
								<button type="submit" className="btn btn-primary w-100 rounded-pill">Submit</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Support
