
import { ApiResponseType } from '../../types/api';
import { axiosInstance } from './index';

export const employeeService = {
    async getAllBranches() {
        const { data: responseData } = await axiosInstance.get(`gymbranchs/getAllGymBranchs`);
        return responseData as ApiResponseType;
    },
    async getEmployeesList() {
        const { data: responseData } = await axiosInstance.get(`gymemployees/getAllGymEmployees`);
        return responseData as ApiResponseType;
    },
    async addEmployee(data: any) {
        //const token = tokenManager.getToken();
        const { data: responseData } = await axiosInstance.post(`gymemployees/createGymEmployee`, data);
        return responseData as ApiResponseType;
    },
    async getGymEmployeesByEmpType(empType: number) {
        const { data: responseData } = await axiosInstance.get(`gymemployees/getGymEmployeesByEmpType/${empType}`);
        return responseData as ApiResponseType;
    },
    async getGymEmployeesByFilter(branchName: any,empStatus: any, empName: any,empMobileNo: any) {
        const { data: responseData } = await axiosInstance.get(`gymemployees/getGymEmployeesByFilter/${branchName}/${empStatus}/${empName}/${empMobileNo}`);
        return responseData as ApiResponseType;
    },
    async getGymEmployeeById(empId: number) {
        const { data: responseData } = await axiosInstance.get(`gymemployees/getGymEmployeeById/${empId}`);
        return responseData as ApiResponseType;
    },
    async updateGymEmployee(empId: number, data: any) {
        const { data: responseData } = await axiosInstance.put(`gymemployees/updateGymEmployee/${empId}`, data);
        return responseData as ApiResponseType;
    },
};