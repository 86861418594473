import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { SessionStorageManager } from '../../lib/sessionlStorage-manager';
import { connect } from 'react-redux';
import { updateDataChild3 } from '../../store/actions/authActions';
import { PackageEnum } from '../../types/package';

function GmsPackages({ showComponent, closePopup ,dataChild3, updateDataChild3} : any) {
    //const [custPackage, setCustPackage] = useState(dataChild3 == null?'Silver':dataChild3);
    const [custPackage, setCustPackage] = useState<PackageEnum>(
        dataChild3 == null ? PackageEnum.Silver : dataChild3
      );

    const handleOptionChange = useCallback((e : any) => {
        const selectedPackage = parseInt(e.target.value, 10) as PackageEnum;
        setCustPackage(selectedPackage);
        //SessionStorageManager.setCustPackage(selectedPackage);
      },[]);
      const handlePkgSubmit = () => {
        showComponent('packageDetails');
        updateDataChild3(custPackage);
      };
    
  return (
    <>
        <div className="d-flex align-items-center justify-content-between p-3">
            <div>
                <h2 className="fw-600 fs-18 mb-0">GYM Entrance System</h2>
                <p className="fs-10 mb-0">Final step to management system.</p>
            </div>
            <button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" onClick={() => closePopup()} aria-label="Close"></button>
        </div>
        <div className="text-center m-auto px-3">
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-house"></i>
                </div>
                <label htmlFor="Silver" className="form-control label-box">
                    <h2 className="fs-16 fw-700">Silver</h2>
                    <p className="mb-0">1 Branch <i className="bi-dot"></i> Full loaded modules <i className="bi-dot"></i> Business page </p>
                </label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" id="Silver" name="cust_package"
                    value={PackageEnum.Silver} onChange={handleOptionChange} checked={custPackage === PackageEnum.Silver} />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-building"></i>
                </div>
                <label htmlFor="Bronze" className="form-control label-box">
                    <h2 className="fs-16 fw-700">Bronze</h2>
                    <p className="mb-0">2 Branch <i className="bi-dot"></i> Full loaded modules <i className="bi-dot"></i> Business page </p>
                </label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" id="Bronze" name="cust_package"
                    value={PackageEnum.Bronze} onChange={handleOptionChange} checked={custPackage === PackageEnum.Bronze} />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-buildings"></i>
                </div>
                <label htmlFor="Gold" className="form-control label-box">
                    <h2 className="fs-16 fw-700">Gold</h2>
                    <p className="mb-0">5 Branch <i className="bi-dot"></i> Full loaded modules <i className="bi-dot"></i> Business page </p>
                </label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" id="Gold" name="cust_package"
                    value={PackageEnum.Gold} onChange={handleOptionChange} checked={custPackage === PackageEnum.Gold} />
                </div>
            </div>
            <div className="input-group business-options mb-3">
                <div className="input-group-text icon-box">
                    <i className="bi-bank"></i>
                </div>
                <label htmlFor="Platinum" className="form-control label-box">
                    <h2 className="fs-16 fw-700">Platinum</h2>
                    <p className="mb-0">Unlimited Branch <i className="bi-dot"></i> Full loaded modules <i className="bi-dot"></i> Business page </p>
                </label>
                <div className="input-group-text selected-box">
                    <input className="form-check-input mt-0" type="radio" id="Platinum" name="cust_package"
                    value={PackageEnum.Platinum} onChange={handleOptionChange} checked={custPackage === PackageEnum.Platinum} />
                </div>
            </div>
            <button type="button" className="btn btn-primary w-100 mb-3" onClick={() => handlePkgSubmit()}>Continue</button>
            {/* <p className="fs-14">Already have an account? <Link to={"."}>Sign In</Link></p> */}
        </div>
    </>
  )
}
const mapStateToProps = (state:any) => ({
    dataChild3: state.children.dataChild3,
  });
  
  export default connect(mapStateToProps, { updateDataChild3 })(GmsPackages);