import React, { useState, useEffect } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';
import LeftNav from './admin-includes/left-nav';
import secureLocalStorage from 'react-secure-storage';
import { Employee } from '../types/employee';

const empTypes = [{ name: 'Admin', value: 1 },{ name: 'Manager', value: 2 }, { name: 'Front Office', value: 3 }, { name: 'Trainer', value: 4 }, { name: 'Zumba Trainer', value: 5 }, { name: 'Attender', value: 6 }];

const Room = () => {
    const outlet = useOutlet();
    const isAuthenticated = true;
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        const fetchUserRole = async () => {
            const custInfo = await secureLocalStorage.getItem("custInfo");
            const customerInfo = {
                ...custInfo as Employee
            };
            const empTypeName = empTypes.find(res => res.value === customerInfo.EmpType)?.name;
            empTypeName && setUserRole(empTypeName);
        };

        fetchUserRole();
    }, []);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    } else {
        return (
            <>
                <div className="container-fluid px-0">
                    <LeftNav userRole={userRole} />
                    <div className="page-body-wrapper">
                        {outlet}
                    </div>
                </div>
            </>
        );
    }
};

export default Room;
