import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { customerService } from '../../lib/api/customer';
import { routes } from '../../routes/routes-names';
import { setCustomerInfo } from '../../store/reducers/customer';
import { useDispatch } from 'react-redux';
import secureLocalStorage from "react-secure-storage";
import { Employee } from '../../types/employee';
import { tokenManager } from '../../lib/token-manager';
const empTypes = [{ name: 'Admin', value: 1 }, { name: 'Manager', value: 2 }, { name: 'Front Office', value: 3 }, { name: 'Trainer', value: 4 }, { name: 'Zumba Trainer', value: 5 }, { name: 'Attender', value: 6 }];

function LoginPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const login = useGoogleLogin({
		onSuccess: async respose => {
			try {
				const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
					headers: {
						"Authorization": `Bearer ${respose.access_token}`
					}
				})
				const { success, data, token } = await customerService.validateEmail({ email: res.data.email });
				if (success) {
					tokenManager.setToken(token);
					secureLocalStorage.setItem("custInfo", data[0]);
					dispatch(setCustomerInfo(data[0]));
					const customerInfo = {
						...data[0] as Employee
					};
					const empTypeName = empTypes.find(res => res.value === customerInfo.EmpType)?.name;
					empTypeName && secureLocalStorage.setItem("userType", empTypeName);
					switch (empTypeName) {
						case 'Admin':
						case 'Manager':
							navigate(routes.dashboard);
							break;
						case 'Trainer':
						case 'Zumba Trainer':
						case 'Front Office':
							navigate(routes.clients);
							break;
						default:
							break;
					}
				}
				else {
					alert("Login failed");
				}
			} catch (err) {
				console.log(err)

			}

		}
	});
	return (
		<>
			<div className="d-flex justify-content-end p-3">
				<button type="button" className="btn-close fs-14" title="Close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div className="col-10 text-center m-auto pt-5">
				<div className="d-flex justify-content-center align-items-center mb-3">

					{/* <GoogleLogin
						onSuccess={async credentialResponse => {
						console.log(credentialResponse);
						if(credentialResponse.credential)
						{
							var decoded = jwtDecode(credentialResponse.credential);
							console.log('decoded' , decoded);
						}
						
						}}
					
						onError={() => {
						console.log('Login Failed');
						}}
					
					/> */}

					<button type="button" onClick={() => login()} data-bs-dismiss="modal" className="btn-google"><i className="bi-google"></i> Sign in with Google</button>
				</div>
				<p className="fs-12">By clicking Sign Up or Sign In, you agree to our <Link to={"."}>Terms</Link> and that you have read our <Link to={"."}>Data Use Policy</Link>, including our <Link to={"."}>Cookie Use</Link>.</p>
				<div className="text-center my-3 fw-700 secondary-alt-text">Or</div>
				<p className="fs-14">Don't Have An Account Yet? <Link to={"."} data-bs-toggle="modal" data-bs-target="#Register">Sign Up</Link></p>
			</div>
		</>
	)
}

export default LoginPage