

export const calculateGST = (subTotal: number) => {
    try {
        const gstRate = 0.18;
        const gstAmount = subTotal * gstRate;
        return Number(gstAmount).toFixed(2);
    } catch (error) {
        console.log('GST Error', error);
    }
};

export const calculateTotal = (subTotal: number) => {
    try {
        const gstAmount = calculateGST(subTotal);
        if(gstAmount)
        {
            const totalAmount = subTotal + Number(gstAmount);
            return Number(totalAmount).toFixed(2);
        }
    } catch (error) {
        console.log('GST Error', error);
    }
};