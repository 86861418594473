import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetBranches } from '../../types/branch';
import { Account } from '../../types/account';
import { accountService } from '../../lib/api/account';
import { branchService } from '../../lib/api/branch';
import secureLocalStorage from 'react-secure-storage';
import { Customer } from '../../types/customer';
import Table from '../../components/Table';

const paymentModes = [{ name: 'Online', value: 1 }, { name: 'Cash', value: 2 }, { name: 'Credit/Debit', value: 3 }];
const paymentTypes = [{ name: 'Maintenance', value: 1 }, { name: 'General Expenses', value: 2 }, { name: 'Software', value: 3 }, { name: 'GYM Payments', value: 4 }, { name: 'PT Payments', value: 5 }];

const PaymentList = () => {
    const fetchIdRef = useRef(0);
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [branches, setBranches] = useState<GetBranches[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [acctPayTowards, setAcctPayTowards] = useState<string | null>(null);
    const [acctPayAmount, setAcctPayAmount] = useState<string | null>(null);
    const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
    const [selectedPayMode, setSelectedPayMode] = useState<number | null>(null);
    const [selectedPayType, setSelectedPayType] = useState<number | null>(null);
    const [selectedPayDate, setSelectedPayDate] = useState("");

    const fetchData = useCallback(async ({ pageSize, pageIndex }: any) => {

        const fetchId = ++fetchIdRef.current

        const payDate = selectedPayDate == "" ? null : selectedPayDate;

        const { success, data } = await accountService.getGymAccountsByFilter(selectedBranchId, selectedPayMode, selectedPayType, acctPayTowards, acctPayAmount, payDate);
        if (success) {
            setLoading(true);

            setTimeout(() => {
                // Only update the data if this is the latest fetch
                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex
                    const endRow = startRow + pageSize
                    setAccounts(data.slice(startRow, endRow))

                    // Your server could send back total page count.
                    // For now we'll just fake it, too
                    setPageCount(Math.ceil(data.length / pageSize));

                    setLoading(false)
                }
            }, 1000);
        }
    }, [selectedBranchId, selectedPayMode, selectedPayType, acctPayTowards, acctPayAmount,selectedPayDate]);

    const getBranchesByCustId = async () => {
        try {
            const custInfo = secureLocalStorage.getItem("custInfo");
            const customerInfo = {
                ...custInfo as Customer
            }
            const { data } = await branchService.getBranchesByCustId(Number(customerInfo.CustId));
            if (data.length) {
                setBranches(data);
            }
        } catch (err) {
            console.log('error from getAllBranches ', err);
        } finally {
            //setLoading(false);
        }
    };

    const handleBranchChange = useCallback((selectedBranch: string) => {
        setSelectedBranchId((prevSelectedBranchId) => {
            // Toggle the selected branch
            if (prevSelectedBranchId === selectedBranch) {
                // Unselect the branch if it was already selected
                return null;
            } else {
                // Select the branch if it was not selected
                return selectedBranch;
            }
        });
    }, [setSelectedBranchId]);

    const handlePayModeChange = useCallback((selectedPayMode: number) => {
        setSelectedPayMode((prevSelectedPayMode) => {
            // Toggle the selected branch
            if (prevSelectedPayMode === selectedPayMode) {
                // Unselect the branch if it was already selected
                return null;
            } else {
                // Select the branch if it was not selected
                return selectedPayMode;
            }
        });
    }, [setSelectedPayMode]);

    const handlePayTypeChange = useCallback((selectedPayType: number) => {
        setSelectedPayType((prevSelectedPayType) => {
            // Toggle the selected branch
            if (prevSelectedPayType === selectedPayType) {
                // Unselect the branch if it was already selected
                return null;
            } else {
                // Select the branch if it was not selected
                return selectedPayType;
            }
        });
    }, [setSelectedPayType]);

    const columns = [
        {
            Header: "Payment Towards",
            accessor: "AcctPayTowards"
        },
        {
            Header: "Branch",
            accessor: "BranchName"
        },
        {
            Header: "Mode",
            accessor: "AcctPayMode"
            // accessor: (row: any) => {
            //     const paymentMode = paymentModes.find(mode => mode.value === row.AcctPayMode);
            //     return paymentMode ? paymentMode.name : 'Unknown';
            // }
        },
        {
            Header: "Type",
            accessor: "AcctPayType"
            // accessor: (row: any) => {
            //     const paymentType = paymentTypes.find(type => type.value === row.AcctPayType);
            //     return paymentType ? paymentType.name : 'NA';
            // }
        },
        {
            Header: "Payment Date",
            accessor: "AcctPayDate",

        },
        {
            Header: "Amount",
            accessor: "AcctPayAmount",
        },
        {
            Header: "Balance",
            accessor: "AcctPayBalance"
        },
        {
            Header: "Action",
            Cell: (cell: any) => (
                <>
                    <button type="button" className="btn btn-hovers" title="Edit"><i className="bi-pencil"
                        onClick={() => {
                            navigate(`/add-edit-payment/${Number(cell.row.original.AcctId)}`)
                        }

                        }></i></button>
                    <button type="button" className="btn btn-hovers" title="Delete"><i className="bi-trash3"></i></button>
                </>
            )
        }
    ];


    useEffect(() => {
        getBranchesByCustId();
    }, []);

    return (
        <>
            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="fw-700 fs-18 secondary-text mb-0">Accounting</h1>
                </div>
                <div>
                    <Link to={"/add-edit-payment"} className="btn btn-hovers btn-sm"><i className="bi-plus-lg"></i> Add Payment</Link>
                </div>
            </div>

            {/* <!-- Page Data Filter --> */}
            <div className="input-group input-group-lg page-data-filter shadow-sm">
                <span className="input-group-text pe-0 ps-2 border-0"><i className="bi-funnel"></i></span>
                <input type="text" className="form-control fs-14 border-0"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value != null && value != "") {
                            setAcctPayTowards(value);
                            setAcctPayAmount(value);
                        }
                        else {
                            setAcctPayTowards(null);
                            setAcctPayAmount(null);
                        }

                    }}
                    placeholder="Search with Payment Towards name or Amount.." />
                <button className="btn btn-outline-secondary dropdown-toggle border-0 fs-14" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Branch</button>
                <ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0">
                    {branches.map((branch) => (
                        <div className="p-2" key={branch.BranchId}>
                            <label htmlFor={`checkbox-${branch.BranchId}`}>
                                <input
                                    id={`checkbox-${branch.BranchId}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedBranchId === branch.BranchName}
                                    name="checkboxGroup"
                                    onChange={() => handleBranchChange(branch.BranchName)}
                                />
                                <span className="form-check-label ms-2 fw-600">{branch.BranchName}</span>
                            </label>
                        </div>
                    ))}
                </ul>
                <button className="btn btn-outline-secondary dropdown-toggle border-0 fs-14" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Payment Mode</button>
                <ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0">
                {paymentModes.map((payMode) => (
                        <div className="p-2" key={payMode.value}>
                            <label htmlFor={`checkbox-mode-${payMode.value}`}>
                                <input
                                    id={`checkbox-mode-${payMode.value}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedPayMode === payMode.value}
                                    name="checkboxPayMode"
                                    onChange={() => handlePayModeChange(payMode.value)}
                                />
                                <span className="form-check-label ms-2 fw-600">{payMode.name}</span>
                            </label>
                        </div>
                    ))}
                </ul>
                <button className="btn btn-outline-secondary dropdown-toggle border-0 fs-14" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Payment Type</button>
                <ul className="dropdown-menu dropdown-menu-end rounded-1 box-shadow-md border-0 py-0">
                {paymentTypes.map((payType) => (
                        <div className="p-2" key={payType.value}>
                            <label htmlFor={`checkbox-type-${payType.value}`}>
                                <input
                                    id={`checkbox-type-${payType.value}`}
                                    className="form-check-input border-0"
                                    type="checkbox"
                                    checked={selectedPayType === payType.value}
                                    name="checkboxPayType"
                                    onChange={() => handlePayTypeChange(payType.value)}
                                />
                                <span className="form-check-label ms-2 fw-600">{payType.name}</span>
                            </label>
                        </div>
                    ))}
                </ul>
                <input type="date" name="" id=""
                                value={selectedPayDate}
                                onChange={(ev) => {
                                    //setAccounts([]);
                                    setSelectedPayDate(ev.target.value)
                                }} className="form-control fs-14 payment-date border-0" />
                <button type="button" className="btn btn-primary search-btn border-0"><i className="bi-search"></i></button>
            </div>
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <Table
                        columns={columns}
                        data={accounts}
                        loading={loading}
                        pageCount={pageCount}
                        fetchData={fetchData}
                    />
                </div>
            </div>
        </>
    )
}

export default PaymentList
