import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import getValue from 'lodash/get';
import Select from 'react-select';
import { branchService } from '../../lib/api/branch';
import { GetBranches } from '../../types/branch';
import moment from 'moment';
import { AccountFormType } from '../../types/account';
import { accountService } from '../../lib/api/account';
import secureLocalStorage from 'react-secure-storage';
import { Customer } from '../../types/customer';

const paymentModes = [{ name: 'Online', value: 1 }, { name: 'Cash', value: 2 }, { name: 'Credit/Debit', value: 3 }];
const paymentTypes = [{ name: 'Maintenance', value: 1 }, { name: 'General Expenses', value: 2 }, { name: 'Software', value: 3 }, { name: 'GYM Payments', value: 4 }, { name: 'PT Payments', value: 5 }];

const AddEditPayment = () => {
    const navigate = useNavigate();
    const { accountId } = useParams();
    const isEditMode = !!accountId;
    const [branches, setBranches] = useState<GetBranches[]>([]);

    const [initialFormData, setInitialFormData] = useState({
        AcctGymBranchId: 0,
        AcctPayTowards: '',
        AcctPayMode: 0,
        AcctPayType: 0,
        AcctPayAmount: '',
        AcctPayBalance: '',
        AcctPayDate: moment().format('YYYY-MM-DD'),
        AcctDescription: ''
    });

    const getBranchesByCustId = async () => {
        try {
            const custInfo = secureLocalStorage.getItem("custInfo");
            const customerInfo = {
                ...custInfo as Customer
            }
            const { data } = await branchService.getBranchesByCustId(Number(customerInfo.CustId));
            if (data.length) {
                setBranches(data);
            }
        } catch (err) {
            console.log('error from getAllBranches ', err);
        } finally {
            //setLoading(false);
        }
    };

    const submitForm = useCallback(
        async (values: AccountFormType) => {
            try {
                if (isEditMode) {
                    // If in edit mode, call the update method
                    const accountData = {
                        ...values,
                        AcctPayAmount: Number(values.AcctPayAmount),
                        AcctPayBalance: Number(values.AcctPayBalance),
                        AcctPayMode: Number(values.AcctPayMode),
                        AcctPayType: Number(values.AcctPayType)
                    };
                    const { success, message } = await accountService.updateAccount(Number(accountId), accountData);
                    if (success) {
                        alert(message);
                        navigate('/accounts');
                    }
                } else {
                    const custInfo = secureLocalStorage.getItem("custInfo");
                    const customerInfo = {
                        ...custInfo as Customer
                    }
                    const accountData = {
                        ...values,
                        AcctGymId: customerInfo.CustId,
                        AcctStatus: 1,
                        AcctPayAmount: Number(values.AcctPayAmount),
                        AcctPayBalance: Number(values.AcctPayBalance),
                        AcctPayMode: Number(values.AcctPayMode),
                        AcctPayType: Number(values.AcctPayType)
                    };
                    const { success, message } = await accountService.createAccount(accountData);
                    if (success) {
                        alert(message);
                        navigate("/accounts");
                    }
                }

            } catch (error) {
                console.log("error == ", error);
            }
        },
        []
    );

    useEffect(() => {
        getBranchesByCustId();
        if (isEditMode && accountId) {
            // Fetch branch data for editing
            const fetchAccountData = async () => {
                try {
                    const { data } = await accountService.getAccountById(Number(accountId));
                    if (data.length) {
                        const accountData = {
                            ...data[0],
                            AcctPayDate: moment(data[0].AcctPayDate).format('YYYY-MM-DD'),
                        }
                        setInitialFormData(accountData);
                    }
                } catch (err) {
                    console.log('error from getClientById ', err);
                }
            };

            fetchAccountData();
        }
    }, [isEditMode, accountId]);

    return (
        <>
            {/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <Link to={"/accounts"} className="btn btn-hovers btn-sm"><i className="bi-arrow-left"></i> Back</Link>
                </div>
                <div>

                </div>
            </div>

            {/* <!-- Add Payment Form --> */}
            <div className="col-12 col-lg-9 col-xl-6 m-auto">
                <h1 className="fs-18 fw-700 text-center w-100 mb-3">{isEditMode ? 'Edit Payment' : 'Add Payment'}</h1>
                <div className="dashboard-card bg-white text-start mb-5">
                    <Formik
                        onSubmit={submitForm}
                        enableReinitialize
                        validationSchema={getValidationSchema}
                        initialValues={initialFormData}
                    >
                        {(formikProps: FormikProps<AccountFormType>) => {
                            const { isValid, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm } = formikProps;

                            return (
                                <><form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="" className="fs-12">Payment Towards <span className="text-danger">*</span></label>
                                        <Field name="AcctPayTowards">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error = getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                return (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={field.value}
                                                            onChange={(ev) => {
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, ev.target.value);
                                                            }}
                                                            className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            placeholder="Enter PayTowards" />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );

                                            }}
                                        </Field>

                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="" className="fs-12 fw-600 mb-2">Branch</label>
                                        <Field name="AcctGymBranchId">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error =
                                                    getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                const selectedBranchOption = branches.find((option) => option.BranchId === field.value);
                                                return (
                                                    <>
                                                        <Select
                                                            name="AcctGymBranchId"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isSearchable
                                                            options={branches}
                                                            placeholder="Select Branch"
                                                            onChange={(selectedOption) => {
                                                                const { BranchId, BranchName } =
                                                                    selectedOption as GetBranches;
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, BranchId);
                                                            }}
                                                            value={selectedBranchOption}
                                                            getOptionLabel={(option) => option.BranchName}
                                                            getOptionValue={(option) => option.BranchId.toString()}
                                                        />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );
                                            }}
                                        </Field>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="" className="fs-12">Payment Mode <span className="text-danger">*</span></label>
                                        <Field name="AcctPayMode">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error =
                                                    getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                const selectedPaymentTypesOption = paymentModes.find((option) => option.value === field.value);
                                                return (
                                                    <>
                                                        <Select
                                                            name="Payment Mode"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isSearchable
                                                            options={paymentModes}
                                                            placeholder="Select Mode"
                                                            onChange={(selectedOption) => {
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, selectedOption?.value);
                                                            }}
                                                            value={selectedPaymentTypesOption}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.value.toString()}
                                                        />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );
                                            }}
                                        </Field>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <div className="col pe-3">
                                            <label htmlFor="" className="fs-12">Payment Date</label>
                                            <Field name="AcctPayDate">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="date"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''
                                                                    }`}
                                                                maxLength={10}
                                                            />

                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="" className="fs-12">Pay Type <span className="text-danger">*</span></label>
                                            <Field name="AcctPayType">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error =
                                                    getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                const selectedPaymentTypesOption = paymentTypes.find((option) => option.value === field.value);
                                                return (
                                                    <>
                                                        <Select
                                                            name="Payment Type"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isSearchable
                                                            options={paymentTypes}
                                                            placeholder="Select Type"
                                                            onChange={(selectedOption) => {
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, selectedOption?.value);
                                                            }}
                                                            value={selectedPaymentTypesOption}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.value.toString()}
                                                        />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );
                                            }}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <div className="col pe-3">
                                            <label htmlFor="" className="fs-12">Pay Amount <span className="text-danger">*</span></label>
                                            <Field name="AcctPayAmount">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Pay Amount" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="" className="fs-12">Pay Balance</label>
                                            <Field name="AcctPayBalance">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error = getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Pay Balance" />
                                                            {error && <small className="text-danger">{error.toString()}</small>}
                                                        </>
                                                    );

                                                }}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="" className="fs-12 fw-600 mb-2">Note</label>
                                        <Field name="AcctDescription">
                                            {(fieldProps: FieldProps) => {
                                                const { field, form } = fieldProps;
                                                const error = getValue(form.touched, field.name) &&
                                                    getValue(form.errors, field.name);
                                                return (
                                                    <>
                                                        <textarea
                                                            value={field.value}
                                                            onChange={(ev) => {
                                                                setFieldTouched(field.name);
                                                                setFieldValue(field.name, ev.target.value);
                                                            }}
                                                            className={`form-control ${error ? 'is-invalid' : ''}`}
                                                            placeholder="Enter Note" />
                                                        {error && <small className="text-danger">{error.toString()}</small>}
                                                    </>
                                                );

                                            }}
                                        </Field>
                                    </div>

                                    <div className="w-100 text-end">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                    </div>
                                </form>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default AddEditPayment

const getValidationSchema = () =>
    objectYup().shape({
        AcctGymBranchId: numberYup()
            .required('Branch is required.'),
        AcctPayTowards: stringYup()
            .required('PayTowards is required.'),
        AcctPayMode: numberYup()
            .required('PayMode is required.'),
        AcctPayType: numberYup()
            .required('PayType is required.'),
        AcctPayAmount: stringYup()
            .required('Amount is required.'),
        AcctPayBalance: stringYup()
            .required('Balance is required.'),
        AcctPayDate: stringYup()
            .required('Date is required.'),
        AcctDescription: stringYup()
            .required('Description is required.')
    });
