import React from 'react'
import { Link } from 'react-router-dom'

const ClientView = () => {
	return (
		<>
			{/* <!-- Page Title --> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <Link to={"/customers"} className="btn btn-hovers btn-sm"><i className="bi-arrow-left"></i> Back</Link>
                </div>
                <div>
                    
                </div>
            </div>

			{/* <!-- Page View --> */}
            <div className="w-75 m-auto">
                {/* <!-- Employee Profile Card --> */}
                <div className="dashboard-card bg-white d-flex align-items-center mb-4">
                    <div className="flex-shrink-1 me-3">
                        <img src="../images/avthar-lg.png" alt="" />
                    </div>
                    <div className="w-100 text-start">
                        <h1 className="fs-22 fw-700">Konda Vamshi Das</h1>
                        <div className="fs-12 mb-2"><i className="bi-gender-trans"></i> Male <i className="bi-dot"></i> Fitness GYM Studio</div>
                        <span className="status-success-bg rounded-pill px-2 py-0 fs-12">Active</span>
                    </div>
                </div>

                {/* <!-- Employee Contact Card --> */}
                <div className="dashboard-card bg-white mb-4 text-start">
                    <h2 className="fw-600 fs-16 text-start mb-3">Contact Us</h2>
                    <p className="fs-14 d-flex align-items-center"><i className="bi-geo-alt me-1"></i> BK Reddy Nagar, Venkataramana Colony, Hyderabad, Telangana 500068</p>
                    <p className="fs-14 d-flex align-items-center mb-0"><i className="bi-at me-1"></i> kondavamshidas@gmail.com <i className="bi-telephone ms-2 me-1"></i> +91 9000992292</p>
                </div>

                {/* <!-- Employee Job Details Card --> */}
                <div className="dashboard-card bg-white mb-4 text-start">
                    <h2 className="fw-600 fs-16 text-start mb-3">Job Details</h2>
                    <p className="fs-14 d-flex align-items-center"><span className="fw-600 me-1">DOJ:</span> 21st May 2020</p>
                    <p className="fs-14 d-flex align-items-center mb-0"><span className="fw-600 me-1">Badge:</span> Morning <i className="bi-dot px-2"></i><span className="fw-600 me-1">Login:</span> 5:30 AM <i className="bi-dot px-2"></i><span className="fw-600 me-1">Logout:</span> 12:30 PM</p>
                </div>

                {/* <!-- Transaction Details Card --> */}
                <div className="dashboard-card bg-white mb-3 text-start">
                    <h2 className="fw-600 fs-16 text-start mb-3">Transaction</h2>
                    {/* <!-- List Item Starts --> */}
                    <a href="employees-view.html" className="card mb-3 div-list-items border-0">
                        <div className="card-body p-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="pe-2">
                                    <img src="../images/payment-avatar.png" alt="" />
                                </div>
                                <div>
                                    <h2 className="fw-600 fs-14 mb-1">Payment Towards</h2>
                                    <p className="mb-0 fs-12"><i className="bi-info-circle"></i> GYM Fee</p>
                                </div>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Payment Date</h2>
                                <p className="mb-0 fs-14">12th Jan 2023</p>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Mode</h2>
                                <p className="mb-0 fs-14">Cash</p>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Amount</h2>
                                <p className="mb-0 fs-14">15,000</p>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Balance</h2>
                                <p className="mb-0 fs-14">2,000</p>
                            </div>
                        </div>
                    </a>
                    {/* <!-- List Item Ends -->
                    <!-- List Item Starts --> */}
                    <a href="employees-view.html" className="card mb-3 div-list-items border-0">
                        <div className="card-body p-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="pe-2">
                                    <img src="../images/payment-avatar.png" alt="" />
                                </div>
                                <div>
                                    <h2 className="fw-600 fs-14 mb-1">Payment Towards</h2>
                                    <p className="mb-0 fs-12"><i className="bi-info-circle"></i> Salary</p>
                                </div>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Payment Date</h2>
                                <p className="mb-0 fs-14">12th Jan 2023</p>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Mode</h2>
                                <p className="mb-0 fs-14">Cash</p>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Amount</h2>
                                <p className="mb-0 fs-14">15,000</p>
                            </div>
                            <div>
                                <h2 className="fw-600 fs-12 mb-1">Balance</h2>
                                <p className="mb-0 fs-14">2,000</p>
                            </div>
                        </div>
                    </a>
                    {/* <!-- List Item Ends --> */}
                </div>
            </div>
		</>
	)
}

export default ClientView
