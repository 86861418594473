import React from 'react'

const EditEmployee = () => {
	return (
		<div>
			<h1>Edit Employee</h1>
		</div>
	)
}

export default EditEmployee
